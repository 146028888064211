import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import { useState, useEffect } from "react";
import { get_branch_dashboard } from '../../api/Dashboard'
const Dashboard = () => {
    const [branchStudent, setBranchStudent] = useState({});
    const fullname = localStorage.getItem('b_name');
    useEffect(() => {
        async function get_data() {
            const DashboardData = await get_branch_dashboard();
            setBranchStudent(DashboardData.data);
        }
        get_data();
    }, []);


    const emailName = localStorage.getItem("email");

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = today.toLocaleString('default', { month: 'short' });
    let yyyy = today.getFullYear();
    today = dd +'-'+ mm+'-'+ yyyy;

    const data = [
        ["2013", "Hours per Day"],
        ["2014", 11],
        ["2015", 2],
        ["2016", 2],
        ["Watch TV", 2],
        ["Sleep", 7], // CSS-style declaration
    ];
    const options = {
        title: "Yearly Performance",
        pieHole: 0.4,
        is3D: false,
        height: 400,
        legend: {
            alignment: 'center',
            position: 'bottom'
        }
    };
    const data1 = [
        ["Year", "Sales", "Expenses", "Profit"],
        ["2014", 1000, 400, 200],
        ["2015", 1170, 460, 250],
        ["2016", 660, 1120, 300],
        ["2017", 1030, 540, 350],
    ];
    const options1 = {
        chart: {
            title: "Company Performance",
            subtitle: "Sales, Expenses, and Profit: 2014-2017",
        }
    };


    return (
        <div className="right-nav" id="dash-dashboard">
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/feather/feather.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/css/vendor.bundle.base.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css" />
            <link rel="stylesheet" type="text/css" href="https://themewagon.github.io/skydash/js/select.dataTables.min.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/css/vertical-layout-light/style.css" />

            <div className="container-fluid">
                <div className="container-scroller">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-12 grid-margin">
                                <div className="row">
                                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                                        <h3 className="font-weight-bold">Welcome {JSON.parse(fullname)}</h3>
                                        {/* <h6 className="font-weight-normal mb-0">Email: {emailName}</h6> */}
                                    </div>
                                    <div className="col-12 col-xl-4">
                                        <div className="justify-content-end d-flex">
                                            <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                                                <button className="btn btn-sm btn-light bg-white fw-bold " type="button" id="dropdownMenuDate2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                    <i className="mdi mdi-calendar"></i>  Today {`(${today})`}
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuDate2">
                                                    <a className="dropdown-item" href="#">January - March</a>
                                                    <a className="dropdown-item" href="#">March - June</a>
                                                    <a className="dropdown-item" href="#">June - August</a>
                                                    <a className="dropdown-item" href="#">August - November</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 grid-margin stretch-card">
                                <div className="card tale-bg">
                                    <div className="card-people mt-auto">
                                        <img src="https://themewagon.github.io/skydash/images/dashboard/people.svg" alt="people" />
                                        <div className="weather-info">
                                            <div className="d-flex">
                                                <div>
                                                    <h2 className="mb-0 font-weight-normal"><i className="icon-sun mr-2"></i>31<sup>C</sup></h2>
                                                </div>
                                                <div className="ml-2">
                                                    <h4 className="location font-weight-normal">Sydney</h4>
                                                    <h6 className="font-weight-normal">Australia</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 grid-margin transparent">
                                <div className="row">
                                <div className="col-md-6 mb-4 stretch-card transparent">
                                        <div className="card card-dark-blue">
                                            <div className="card-body">
                                                <p className="mb-4">Total Courses</p>
                                                <p className="fs-30 mb-2">{branchStudent.course}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4 stretch-card transparent">
                                        
                                        <div className="card card-tale">
                                            <div className="card-body">
                                                <p className="mb-4">Total Teachers</p>
                                                <p className="fs-30 mb-2">{branchStudent.teacher}</p>
                                                {/* <p>10.00% (30 days)</p> */}
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                                        <div className="card card-light-blue">
                                            <div className="card-body">
                                                <p className="mb-4">Total Enroll Student</p>
                                                <p className="fs-30 mb-2">{branchStudent.enrolledSt}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 stretch-card transparent">
                                        <div className="card card-light-danger">
                                            <div className="card-body">
                                                <p className="mb-4">Total Free Student</p>
                                                <p className="fs-30 mb-2">{branchStudent.freeSt}</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent" style={{marginTop:'16px'}}>
                                        <div className="card card-tale">
                                            <div className="card-body">
                                                <p className="mb-4">Total Questionnaire</p>
                                                <p className="fs-30 mb-2">{branchStudent.question}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent" style={{marginTop:'16px'}}>
                                        <div className="card card-dark-blue">
                                            <div className="card-body">
                                                <p className="mb-4">Total Student Attendance</p>
                                                <p className="fs-30 mb-2">{branchStudent.attendance}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">

                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="400px"
                                            data={data1}
                                            options={options1}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <p className="card-title">Sales Report</p>
                                            <a href="#" className="text-info-manin">View all</a>
                                        </div>
                                        <p className="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
                                        <div id="sales-legend" className="chartjs-legend mt-4 mb-2"></div>
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="400px"
                                            data={data}
                                            options={options}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {/* <div className="col-md-4 stretch-card grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <p className="card-title mb-0">Branches</p>
                                        <div className="table-responsive">
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0  pb-2 border-bottom">Branch Name</th>
                                                        <th className="border-bottom pb-2">Monthly Income</th>
                                                        <th className="border-bottom pb-2">Total Income</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">B11101</td>
                                                        <td><p className="mb-0"><span className="font-weight-bold mr-2">6500</span>(2.15%)</p></td>
                                                        <td className="text-muted">65000</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">B11102</td>
                                                        <td><p className="mb-0"><span className="font-weight-bold mr-2">5400</span>(3.25%)</p></td>
                                                        <td className="text-muted">51000</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">B11103</td>
                                                        <td><p className="mb-0"><span className="font-weight-bold mr-2">2200</span>(2.22%)</p></td>
                                                        <td className="text-muted">32000</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">B11104</td>
                                                        <td><p className="mb-0"><span className="font-weight-bold mr-2">4600</span>(3.27%)</p></td>
                                                        <td className="text-muted">15000</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">B11105</td>
                                                        <td><p className="mb-0"><span className="font-weight-bold mr-2">1700</span>(1.25%)</p></td>
                                                        <td className="text-muted">25000</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">B11106</td>
                                                        <td><p className="mb-0"><span className="font-weight-bold mr-2">5200</span>(3.11%)</p></td>
                                                        <td className="text-muted">71000</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0 pb-0">B11106</td>
                                                        <td className="pb-0"><p className="mb-0"><span className="font-weight-bold mr-2">2500</span>(1.32%)</p></td>
                                                        <td className="pb-0">14000</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-4 stretch-card grid-margin">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <p className="card-title">Monthy Income</p>
                                                <div className="charts-data">
                                                    <div className="mt-3">
                                                        <p className="mb-0">January</p>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="progress progress-md flex-grow-1 mr-4">
                                                                <div className="progress-bar bg-inf0" role="progressbar" style={{ width: "95%" }} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <p className="mb-0">5k</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="mb-0">February</p>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="progress progress-md flex-grow-1 mr-4">
                                                                <div className="progress-bar bg-info" role="progressbar" style={{ width: "35%" }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <p className="mb-0">1k</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="mb-0">March</p>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="progress progress-md flex-grow-1 mr-4">
                                                                <div className="progress-bar bg-info" role="progressbar" style={{ width: "48%" }} aria-valuenow="48" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <p className="mb-0">992</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="mb-0">April</p>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="progress progress-md flex-grow-1 mr-4">
                                                                <div className="progress-bar bg-info" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <p className="mb-0">687</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 stretch-card grid-margin grid-margin-md-0">
                                        <div className="card data-icon-card-primary">
                                            <div className="card-body">
                                                <p className="card-title text-white">Number of Assessments</p>
                                                <div className="row">
                                                    <div className="col-8 text-white">
                                                        <h3>34040</h3>
                                                        <p className="text-white font-weight-500 mb-0">The total number of assesments within the month.It is calculated as the sum . </p>
                                                    </div>
                                                    <div className="col-4 background-icon">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4 stretch-card grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <p className="card-title">Notifications</p>
                                        <ul className="icon-data-list">
                                            <li>
                                                <div className="d-flex">
                                                    <img src="https://themewagon.github.io/skydash/images/faces/face1.jpg" alt="user" />
                                                    <div>
                                                        <p className="text-info mb-1">Isabella Becker</p>
                                                        <p className="mb-0">Sales dashboard have been created</p>
                                                        <small>9:30 am</small>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex">
                                                    <img src="https://themewagon.github.io/skydash/images/faces/face2.jpg" alt="user" />
                                                    <div>
                                                        <p className="text-info mb-1">Adam Warren</p>
                                                        <p className="mb-0">You have done a great job #TW111</p>
                                                        <small>10:30 am</small>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex">
                                                    <img src="https://themewagon.github.io/skydash/images/faces/face3.jpg" alt="user" />
                                                    <div>
                                                        <p className="text-info mb-1">Leonard Thornton</p>
                                                        <p className="mb-0">Sales dashboard have been created</p>
                                                        <small>11:30 am</small>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex">
                                                    <img src="https://themewagon.github.io/skydash/images/faces/face4.jpg" alt="user" />
                                                    <div>
                                                        <p className="text-info mb-1">George Morrison</p>
                                                        <p className="mb-0">Sales dashboard have been created</p>
                                                        <small>8:50 am</small>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex">
                                                    <img src="https://themewagon.github.io/skydash/images/faces/face5.jpg" alt="user" />
                                                    <div>
                                                        <p className="text-info mb-1">Ryan Cortez</p>
                                                        <p className="mb-0">Herbs are fun and easy to grow.</p>
                                                        <small>9:00 am</small>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <p className="card-title">Branch-wise Review</p>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <table id="example" className="display expandable-table" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Sr no#</th>
                                                                <th>Branch Name</th>
                                                                <th>Total Students</th>
                                                                <th>Enrolled</th>
                                                                <th>Income (Total)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                branchStudent?.branchStudent && branchStudent?.branchStudent?.map((ele, index) => {
                                                                    return (
                                                                        <tr className="odd">
                                                                            <td className="select-checkbox">#{index + 1}</td>
                                                                            <td className="sorting_1">{ele?.branch_name}</td>
                                                                            <td>{ele.branch_count}</td>
                                                                            <td>{ele.branch_code}</td>
                                                                            <td>$2,500</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>   <script src="https://themewagon.github.io/skydash/vendors/js/vendor.bundle.base.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/chart.js/Chart.min.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/datatables.net/jquery.dataTables.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.js"></script>
            <script src="https://themewagon.github.io/skydash/js/dataTables.select.min.js"></script>
            <script src="https://themewagon.github.io/skydash/js/off-canvas.js"></script>
            <script src="https://themewagon.github.io/skydash/js/hoverable-collapse.js"></script>
            <script src="https://themewagon.github.io/skydash/js/template.js"></script>
            <script src="https://themewagon.github.io/skydash/js/settings.js"></script>
            <script src="https://themewagon.github.io/skydash/js/todolist.js"></script>
            <script src="https://themewagon.github.io/skydash/js/dashboard.js"></script>
            <script src="https://themewagon.github.io/skydash/js/Chart.roundedBarCharts.js"></script>

        </div>
    )
};

export default Dashboard;