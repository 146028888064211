import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  check_exit_id,
  get_countries,
  member_add,
} from "../../../apis/studentManagement/StudentManagement";
import { course_type_all, state_branch_all } from "../../../apis/Branches";
import { grade_all } from "../../../apis/Grade";
import { get_days, get_states, get_terms_by_state } from "../../../apis/Common";
import { select_course_all_by_gradeID } from "../../../apis/Course.jsx";
import { handleNavigation } from "../../../Utils/Utils.js";
const AddMembers = () => {
  const [course, setCourse] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const [statelist, setStateList] = useState([]);
  const [coursetypeList, setCoursetypeList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [days, setDays] = useState([]);
  const [termData, setTermData] = useState([]);
  const [showMsg, setShowMsg] = useState("");
  const [checkUserID, setCheckUserId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkAvailability, setCheckAvailability] = useState(false);
  const [gradeID, setGradeId] = useState("");
  const [stateID, setStateId] = useState("");
  const [selectedCourse, setSelectedCourse] = useState([]);
  const {
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const password = useRef({});
  const classroom_gmail = useRef({});
  const p_email = useRef({});
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const branch_id = watch("branch");

  useEffect(() => {
    const today = new Date();
    const maxDateCalc = new Date(
      today.getFullYear() - 0,
      today.getMonth(),
      today.getDate()
    );
    const minDateCalc = new Date(today.getFullYear() - 20, 0, 2);
    setMaxDate(maxDateCalc.toISOString().split("T")[0]);
    setMinDate(minDateCalc.toISOString().split("T")[0]);
  }, []);

  password.current = watch("password", "");
  classroom_gmail.current = watch("classroom_gmail", "");
  p_email.current = watch("p_email", "");

  if (getValues("country")?.length == 0) {
    setValue("country", 14);
  }
  const onSubmit = async (data) => {
    data.course = selectedCourse;
    if (isSubmitting) return;
    setIsSubmitting(true);

    data.pc_email = "";
    let result = await member_add(data);
    if (result.status) {
      return navigate("/admin/students/members");
    }
    setIsSubmitting(false);
  };
  const check_User_id = async () => {
    const emailValue = getValues("email");

    if (!emailValue) {
      toast.error("Please enter user id");
    } else {
      if (checkAvailability) return;
      setCheckAvailability(true);
      let res = await check_exit_id(emailValue);
      if (res) {
        setShowMsg(res?.message);
      }
    }
    setCheckAvailability(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const courseTypeList = await course_type_all();
      const gradeList = await grade_all();
      const countryList = await get_countries();
      const days = await get_days();
      const stateList = await get_states();
      setStateList(stateList?.data);
      setCoursetypeList(courseTypeList?.data);
      setGradeList(gradeList?.data);
      setCountries(countryList?.data);
      setDays(days?.data);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const course = await select_course_all_by_gradeID(gradeID);
      const data = course?.data?.map((item) => ({
        value: item?.id,
        label: item.name,
      }));
      setCourse(data);
    };
    const data = async () => {
      const branchList = await state_branch_all(stateID);
      setBranchList(branchList?.data);
    };
    data();
    fetchData();
  }, [stateID]);
  useEffect(() => {
    const fetchData = async () => {
      const terms = await get_terms_by_state(stateID);
      setTermData(terms?.data);
    };
    fetchData();
  }, [stateID]);

  return (
    <>
      <div className="right-nav" id="dash-event">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create New Account</h4>
            <Link
              className="edit-btn"
              to={`/admin/students/members`}
              onClick={(e) => {
                handleNavigation(e, isDirty);
              }}
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </Link>
          </div>
          <div className="modal-body">
            <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    <b>Student information</b>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder="Enter First Name"
                      {...register("first_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.first_name && errors.first_name && (
                      <span className="validation-error">
                        {errors.first_name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      placeholder="Enter Last Name"
                      {...register("last_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.last_name && (
                      <span className="validation-error">
                        {errors.last_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender *</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("gender", { required: true })}
                    >
                      <option value="">Choose Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errors.gender && errors.gender.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date of Birth *</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date_ob"
                      max={maxDate}
                      min={minDate}
                      {...register("date_of_birth", {
                        required: "Date of birth is required",
                        validate: (value) => {
                          const today = new Date();
                          const birthDate = new Date(value);
                          const age =
                            today.getFullYear() - birthDate.getFullYear();

                          return (
                            (age >= 0 && age <= 20) ||
                            "Age must be below 20 years"
                          );
                        },
                      })}
                      // onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                    />
                    {errors.date_of_birth && (
                      <span className="validation-error">
                        {errors.date_of_birth.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>School Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="schoole_name"
                      placeholder="Enter School Name"
                      {...register("school", {
                        required: "This is required",
                      })}
                    />
                    {errors.school && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      placeholder="Enter Address"
                      {...register("address", {
                        required: "This is required",
                      })}
                    />
                    {errors.address && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State *</label>
                    <select
                      className="form-select form-control"
                      {...register("state", {
                        required: "This is required",
                        onChange: (e) => {
                          setStateId(e.target.value);
                          setValue("term_id", "");
                          setValue("branch", "");
                        },
                      })}
                    >
                      {statelist?.length > 0
                        ? [
                            <option value="">Choose State</option>,
                            statelist?.map((elem) => {
                              return (
                                <option value={elem?.id}>{elem?.name}</option>
                              );
                            }),
                          ]
                        : [<option value="">State not available</option>]}
                    </select>
                    {errors.state && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Branch *</label>
                    <select
                      className="form-select form-control"
                      name="branch"
                      {...register("branch", {
                        required: "This is required",
                      })}
                    >
                      {branchlist?.length > 0
                        ? [
                            <option value="">Choose Branch</option>,
                            branchlist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.branch_name}
                                </option>
                              );
                            }),
                          ]
                        : [
                            // <option value="">Choose Branch</option>
                            //   ,
                            <option value="">Branch not available</option>,
                          ]}
                    </select>
                    {errors.branch && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Grade *</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("grade", {
                        required: "This is required",
                      })}
                      onChange={(e) => setGradeId(e.target.value)}
                    >
                      <option value="">Choose Grade</option>
                      {gradelist?.length > 0 ? (
                        gradelist.map((elem) => (
                          <option key={elem?.id} value={elem?.id}>
                            {elem.name === 0
                              ? `Year ${elem.name} (kindy)`
                              : `Year ${elem.name}`}
                          </option>
                        ))
                      ) : (
                        <option>No result</option>
                      )}
                      <option value="other">Other</option>
                    </select>
                    {gradeID
                      ? ""
                      : errors.grade && (
                          <span className="validation-error">
                            {errors.grade.message}
                          </span>
                        )}
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Course Type</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("subject_id")}
                    >
                      <option value="">Choose course Type</option>
                      {coursetypeList?.length > 0 ? (
                        coursetypeList &&
                        coursetypeList?.map((elem) => {
                          return (
                            <option value={elem?.id}>
                              {elem?.category_name}
                            </option>
                          );
                        })
                      ) : (
                        <option>No result</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className={`col-md-4`}>
                  <div className="form-group">
                    <label>Term *</label>
                    <select
                      className="form-select form-control"
                      {...register("term_id", {
                        required: true,
                      })}
                    >
                      <option value="">Choose Term </option>
                      {termData &&
                        termData?.map((elem) => {
                          return <option value={elem?.id}>{elem?.name}</option>;
                        })}
                    </select>
                    {errors.term_id && errors.term_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className={`col-md-4`}>
                  <div className="form-group">
                    <label>Day </label>
                    <select
                      className="form-select form-control"
                      {...register("day")}
                    >
                      <option value="">Choose Day</option>
                      {days &&
                        days?.map((elem) => {
                          return <option value={elem?.id}>{elem?.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className={`col-md-4`}>
                  <div className="form-group">
                    <label>Student Type *</label>
                    <select
                      className="form-select form-control"
                      {...register("student_type", {
                        required: true,
                      })}
                    >
                      <option value="">Choose student type</option>
                      <option value="0">Free</option>
                      <option value="1">Enroll</option>
                    </select>
                    {errors.student_type && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr className="mt-3" />
                  <div className="form-group">
                    <label>
                      <b>Step 2. Parent information</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Parent's First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="venue_name"
                      placeholder="Enter Parent First Name"
                      {...register("p_first_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.p_first_name && (
                      <span className="validation-error">
                        {errors.p_first_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Parent's Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="venue_name"
                      placeholder="Enter Parent Last  Name"
                      {...register("p_last_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.p_last_name && (
                      <span className="validation-error">
                        {errors.p_last_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Primary contact *</label>
                    <div className="input-group branch-prepend">
                      <div className="input-group-prepend">
                        <div className="input-group-text">+61</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                       placeholder="04XX XXX XXX"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="offine_seats"
                        {...register("mother_mobilenumber", {
                          required: "Mobile number  is required",
                          pattern: {
                            value: /^0[2-8]\d{8}$|^04\d{2}\s\d{3}\s\d{3}$/,
                            message:
                              "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX)",
                          },
                          validate: {
                            isNumeric: (value) =>
                              value === "" ||
                              /^\d+$/.test(value.replace(/\s+/g, "")) ||
                              "Phone number must contain only numbers",
                          },
                        })}
                        maxLength={10}
                      />
                    </div>
                    {errors.mother_mobilenumber && (
                      <span className="validation-error">
                        {errors.mother_mobilenumber.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Alternative Contact </label>
                    <div className="input-group branch-prepend">
                      <div className="input-group-prepend">
                        <div className="input-group-text">+61</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="online_url"
                        placeholder="04XX XXX XXX"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("father_mobilenumber", {
                          pattern: {
                            value: /^0[2-8]\d{8}$|^04\d{2}\s\d{3}\s\d{3}$/,
                            message:
                              "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX)",
                          },
                          validate: {
                            isNumeric: (value) =>
                              value === "" ||
                              /^\d+$/.test(value.replace(/\s+/g, "")) ||
                              "Phone number must contain only numbers",
                          },
                        })}
                        maxLength={10}
                      />
                    </div>
                    {errors.father_mobilenumber && (
                      <span className="validation-error">
                        {errors.father_mobilenumber.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter City"
                      name="description"
                      {...register("city", { required: true })}
                    />
                    {errors.city && errors.city.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Parent's Email *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Parent Email"
                      name="p_email"
                      {...register("p_email", {
                        required: true,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                    {errors.p_email && errors.p_email.type === "required" && (
                      <span className="validation-error">
                        Parent's email is required
                      </span>
                    )}
                    {errors.p_email && (
                      <span className="validation-error">
                        {errors.p_email.message}
                      </span>
                    )}
                  </div>
                </div>
                <hr className="mt-3" />
                <div className="form-group">
                  <label>
                    <b>Step 3. Sibling Information</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sibling</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Sibling"
                      name="sibling"
                      {...register("sibling", {
                        maxLength: {
                          value: 15,
                          message: "Sibling name cannot exceed 15 characters",
                        },
                      })}
                    />
                    {errors.sibling && (
                      <div className="text-danger">
                        {errors.sibling.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sibling Grade</label>
                    <select
                      className="form-control form-select"
                      name="grade"
                      {...register("sibling_grade")}
                    >
                      <option value="">Choose Grade</option>
                      {gradelist?.length > 0 ? (
                        gradelist.map((elem) => (
                          <option key={elem?.id} value={elem?.id}>
                            {elem.name === 0
                              ? `Year ${elem.name} (kindy)`
                              : `Year ${elem.name}`}
                          </option>
                        ))
                      ) : (
                        <option>No result</option>
                      )}
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <hr className="mt-3" />
                  <div className="form-group">
                    <label>
                      <b>Step 4. Choose a user name and password</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label>Email ID *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      autoComplete="off"
                      placeholder="Enter User Email"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                          message: "Please enter a valid email address",
                        },
                        onChange: (e) => {
                          setCheckUserId(e.target.value);
                        },
                      })}
                    />
                    {!checkUserID &&
                      errors.email &&
                      errors.email.type === "required" && (
                        <span className="validation-error">
                          Email is required
                        </span>
                      )}
                    {errors.email && (
                      <span className="validation-error">
                        {errors.email.message}
                      </span>
                    )}
                    <div
                      style={
                        showMsg === "It is available!"
                          ? { color: "#076905" }
                          : { color: "red" }
                      }
                      className="col-md-12  text-transform-none"
                    >
                      {showMsg}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div
                      className="ui button mt-4"
                      onClick={() => check_User_id()}
                      // disabled={checkAvailability}
                    >
                      Check Availability of this Email ID
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password: *</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      autocomplete="new-password"
                      placeholder="Enter User Password"
                      {...register("password", {
                        required: true,
                        // pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                      })}
                    />
                    {/* {errors.password && errors.password.type === "pattern" && <span className="validation-error">At least 6 chars long,digit,upper case,lower case</span>} */}
                    {errors.password && errors.password.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Confirm Password: *</label>
                    <input
                      type="password"
                      placeholder="Enter User Confirm Password"
                      className="form-control"
                      name="confirmPassword"
                      {...register("confirmPassword", {
                        required: true,
                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      })}
                    />
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    {errors.confirmPassword && (
                      <p className="validation-error">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <Button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">
                Check a user name(EMAIL) you want to use
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <label>Email ID</label>
                  <input
                    className="form-control"
                    value={checkUserID}
                    onChange={(e) => setCheckUserId(e.target.value)}
                    name="user_id"
                    required
                  />
                  <br />
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="ui button"
                      onClick={() => check_User_id()}
                    >
                      Check!!
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 text-center text-transform-none">
                  {showMsg}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddMembers;
