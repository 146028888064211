import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../../Utils/Utils";
import { subject_all } from "../../../../apis/ClassManagement/Subjects";
import {all_test_kind } from "../../../../apis/Common";
import { grade_all } from "../../../../apis/Grade";
import { get_all_year_data } from "../../../../apis/generalSetting/WebsiteEnv";
import { add_testType } from "../../../../apis/generalSetting/testType";
import { get_terms_by_year } from "../../../../apis/Common";
import { course_type_all } from "../../../../apis/Branches";
const AddTest_type = () => {
  const [checkValidation, setCheckValidation] = useState(true);
  const [subject, setSubject] = useState([]);
  const [yeardata, setYearData] = useState([]);
  const [termdata, setTermData] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [tesKind, setTestKind] = useState([]);
  const [courseData, setCourseData] = useState([]);
  
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const year_id = watch("year");
  const testTypeId = watch("type_id");
  useEffect(() => {
    const get_data = async () => {
      const subject = await subject_all();
      const year = await get_all_year_data();
      const grade = await grade_all();
      const kind = await all_test_kind();
      const courseList = await course_type_all();
      setSubject(subject?.data);
      setYearData(year?.data);
      setGradeData(grade?.data);
      setTestKind(kind?.data);
      setCourseData(courseList?.data);
    };
    get_data();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const term = await get_terms_by_year(year_id);
      setTermData(term?.data);
    };
    fetchData();
  }, [year_id]);
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (!checkValidation) {
    } else {
      let result = await add_testType(data);
      if (result.status === true) {
        return navigate("/admin/general-setting/test-type/all");
      }
      setIsSubmitting(false);
    }
  };

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Add test type</h4>
          </div>
          <div className="col-md-6 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/general-setting/test-type/all`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Test type *</label>
                  <select
                    className="form-select form-control"
                    name="type"
                    {...register("type_id", { required: true })}
                  >
                    <option value="">Choose test type</option>
                    {tesKind &&
                      tesKind?.map((elem) => (
                        <option value={elem?.id}>{elem?.name}</option>
                      ))}
                  </select>
                  {errors.type_id && errors.type_id.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Year *</label>
                  <select
                    className="form-select form-control"
                    name="year"
                    {...register("year", { required: true ,onChange:()=>{setValue('term','')} })}
                  >
                    <option value="">Choose year</option>
                    {yeardata &&
                      yeardata?.map((elem) => (
                        <option value={elem?.id}>{elem?.current_year}</option>
                      ))}
                  </select>
                  {errors.year && errors.year.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Term *</label>
                  <select
                    className="form-select form-control"
                    name="term"
                    {...register("term", { required: true})}
                  >
                    <option value="">Choose term</option>
                    {termdata &&
                      termdata?.map((elem) => (
                        <option value={elem?.id}>{elem?.name}</option>
                      ))}
                  </select>
                  {errors.term && errors.term.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Course Type *</label>
                  <select
                    className="form-select form-control"
                    name="course_id"
                    {...register("course_type_id", { required: true })}
                  >
                    <option value="">Choose course type</option>
                    {courseData &&
                      courseData?.map((elem) => (
                        <option value={elem?.id}>{elem?.category_name}</option>
                      ))}
                  </select>
                  {errors.course_type_id && errors.course_type_id.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Grade *</label>
                  <select
                    className="form-select form-control"
                    name="grade"
                    {...register("grade", { required: true })}
                  >
                    <option value="">Choose grade</option>
                    {gradeData &&
                      gradeData?.map((elem) => (
                        <option value={elem?.id}>Year {elem?.name}</option>
                      ))}
                  </select>
                  {errors.grade && errors.grade.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Test title *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter test title"
                    {...register("test_title", { required: true })}
                  />
                  {errors.test_title &&
                    errors.test_title.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Subject *</label>
                  <select
                    className="form-select form-control"
                    name="subject"
                    {...register("subject_id", { required: true })}
                  >
                    <option value="">Choose subject</option>
                    {subject &&
                      subject?.map((elem) => (
                        <option value={elem?.id}>{elem?.subject}</option>
                      ))}
                  </select>
                  {errors.subject_id &&
                    errors.subject_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Test period (in days) *</label>
                  <div className="days">
                    <input
                      type="text"
                      className="form-control enter_text_text"
                      placeholder="Enter test period"
                      {...register("test_period", { required: true })}
                      maxLength={3}
                    />
                     <div className="input-group-prepend">
                      <div className="input-group-text day_btn_text">Days</div>
                    </div>
                  </div>
                  {errors.test_period &&
                      errors.test_period.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label>TSTM GUID </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter TSTM GUID"
                    {...register("tstm_guid")}
                  />
                </div>
              </div> */}
              <div className="row">
                      {testTypeId === "1" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>TSTM GUID *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter TSTM GUID"
                              {...register("tstm_guid", {
                                required: testTypeId === "1" ? true : false,
                              })}
                            />
                            {errors.tstm_guid && errors.tstm_guid.type === "required" && (
                              <span className="validation-error">This is required</span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn button"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddTest_type;
