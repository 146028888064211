import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button } from "semantic-ui-react";
import { get_terms_by_year } from "../../../apis/Common.jsx";
import { get_all_year_data } from "../../../branches/api/common.jsx";
import { dublicate_course } from "../../../apis/Course.jsx";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
export const DuplicateCourse = ({ isModalOpen, setIsModalOpen, ids }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
    setValue,
    reset,
  } = useForm();
  const [termData, setTermData] = useState([]);
  const [selectedOptions, setSlectedOptions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await get_terms_by_year(ids?.year);
      const transformedOptions = data?.data?.map((item) => ({
        value: item?.id,
        label: item.name,
      }));
      setTermData(transformedOptions);
    };
    console.log('termData',termData);
    
    const fetchYear = async () => {
      const selectTermData = ids?.selectedTermData?.map((elem) => ({
        value: elem?.id,
        label: elem.name,
      }));
      setSlectedOptions(selectTermData);
    };
    if (ids?.year) {
      fetchData();
    }
    fetchYear();
  }, [ids?.year, isModalOpen]);
  const onsubmit = async () => {
    const payload = {
      termIds: selectedOptions?.map((ele) => ele?.value),
    };
    const result = await dublicate_course(ids?.course_id, ids?.year, payload);
    if (result?.status) {
      setIsModalOpen(false);
    }
  };
  const resetData = () => {
    // setValue("term_id", "");
    setValue("year", "");
    setSlectedOptions([]);
    reset();
  };
  const handleTermChange = (selectedSubOptions) => {
    setSlectedOptions(selectedSubOptions);
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => {
        setIsModalOpen(false);
      }}
      shouldCloseOnOverlayClick={false}
      className="modal-content"
      overlayClassName="modal-overlay"
      contentLabel=" Modal"
    >
      <div className="modal-header assign-student">
        <h1 className="modal-title fs-5">Duplicate Course</h1>
        <button
          type="button"
          onClick={() => {
            setIsModalOpen(false);
            resetData();
          }}
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <form
        className="modal-body"
        onSubmit={handleSubmit(onsubmit)}
        encType="multipart/form-data"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Grade</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter total units"
                value={ids?.grade}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Year *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter total units"
                value={ids?.year}
                disabled
              />
            </div>
          </div>
          <div className="col-md-12">
            <label>Term *</label>
            <Controller
              name="term"
              control={control}
              defaultValue={[]}
              rules={{
                validate: (value) => value.length > 0 || "This is required",
              }}
              render={({ field }) => (
                <Select
                  options={termData}
                  placeholder="Choose Terms"
                  isMulti
                  {...field}
                  value={selectedOptions}
                  onChange={(otopn) => {
                    handleTermChange(otopn);
                    field.onChange(otopn);
                  }}
                />
              )}
            />
            {errors?.term && (
              <span className="validation-error">{errors?.term?.message}</span>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            type="button"
            className="btn button"
            onClick={() => {
              setIsModalOpen(false);
              resetData();
            }}
          >
            Close
          </Button>
          <Button type="submit" className="btn button">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};
