import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExportToExcelCustome } from "../../../../Utils/Utils";
import { Student_year_to_year_comparison_data } from "../../../../apis/reports/Reports";
const StudentYearComparison = ({ course_id, state, branch_id }) => {
  const [comparisonData, setComparisonData] = useState([]);
  const [years, setYears] = useState([]);
  const terms = ["Term1", "Term2", "Term3", "Term4"];
  useEffect(() => {
    const fetchData = async () => {
      // const filter = JSON.parse(localStorage.getItem("reportsFilter"));
      try {
        const response = await Student_year_to_year_comparison_data(
          branch_id ? branch_id : "",
          course_id ? course_id : "",
          state ? state : ""
        );
        setComparisonData(response?.data || []);
        const allYears = new Set();
        response?.data?.forEach((branch) => {
          branch.grades.forEach((grade) => {
            Object.values(grade.terms).forEach((term) => {
              Object.keys(term).forEach((year) => allYears.add(year));
            });
          });
        });
        setYears(Array.from(allYears).sort());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [course_id, state, branch_id]);
  const handleExportExcel = () => {
    const headers = [
      "Sr. No.",
      "Branch",
      "Grade",
      ...terms.flatMap((term) =>
        years.map((year) => `${term.replace(/(\D)(\d)/, '$1 $2')} ${year}`)
      ),
    ];
    const formatData = comparisonData.flatMap((branch, branchIndex) =>
      branch.grades.map((grade, gradeIndex, gradeArray) => {
        const srNo = branchIndex * gradeArray.length + gradeIndex + 1;
        return [
          srNo,
          branch.branch_name,
          grade.grade_name,
          ...terms.flatMap((term) =>
            years.map((year) => grade.terms[term]?.[year] || 0)
          ),
        ];
      })
    );
    formatData.unshift(headers);
    ExportToExcelCustome(formatData, "year_comparison_report", true);
  };
  let rowIndex = 1;
  return (
    <div className="modal-content">
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div className="modal-header">
            <h4 className="modal-title">Year-on-Year Comparison Summary</h4>
            {comparisonData?.length > 0 && (
              <div
                className="download_icon"
                onClick={() => handleExportExcel()}
              >
                <i className="fa-solid fa-download"></i>
              </div>
            )}
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th rowSpan="3" className="pb-4">
                    Sr no .
                  </th>
                  <th rowSpan="3" className="pb-4">
                    Branch
                  </th>
                  <th rowSpan="3" className="pb-4">
                    Grade
                  </th>
                  {terms.map((term) => (
                    <th
                    key={term}
                    colSpan={years.length}
                    className="text-center"
                  >
                    {term.replace(/(\D)(\d)/, '$1 $2')} 
                  </th>
                  ))}
                </tr>
                <tr>
                  {terms.map((term) =>
                    years.map((year) => 
                    <th key={`${term}-${year}`}>{year}</th>
                )
                  )}
                </tr>
              </thead>
              <tbody>
                {comparisonData.map((branch) =>
                  branch.grades.map((grade) => (
                    <tr key={`${branch.branch_id}-${grade.grade_id}`}>
                      <td>{rowIndex++}</td>
                      <td>{branch.branch_name}</td>
                      <td>{grade.grade_name}</td>
                      {terms.map((term) =>
                        years.map((year) => (
                          <td key={`${term}-${year}`}>
                            {grade.terms[term][year] || 0}
                          </td>
                        ))
                      )}
                    </tr>
                  ))
                )}
                <tr>
                  <td colSpan="3" className="text-center">
                    <b>Total</b>
                  </td>
                  {terms.map((term) =>
                    years?.length > 0 ? (
                      years.map((year) => {
                        const total = comparisonData.reduce(
                          (branchSum, branch) =>
                            branchSum +
                            branch.grades.reduce(
                              (gradeSum, grade) =>
                                gradeSum + (grade.terms?.[term]?.[year] || 0),
                              0
                            ),
                          0
                        );
                        return (
                          <td key={`total-${term}-${year}`}>{total || 0}</td>
                        );
                      })
                    ) : (
                      <td key={`total-${term}`}>{0}</td>
                    )
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentYearComparison;
