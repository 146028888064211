import { Link } from "react-router-dom";
import { Current_year_reports_data } from "../../../../apis/reports/Reports";
import { useEffect, useState } from "react";
import { ExportToExcel } from "../../../../Utils/Utils";
const StudentTotalEnrollment = ({ course_id, state, branch_id }) => {
  const [CurrentYearData, setCurrentYearData] = useState([]);
  useEffect(() => {
    const getCurrentYearData = async () => {
      const data = await Current_year_reports_data(
        branch_id ? branch_id : '',
        course_id ? course_id : '',
        state ? state : ''
      );
      setCurrentYearData(data?.data);
    };
    getCurrentYearData();
  }, [course_id, state, branch_id]);

  const exportExsl = () => {
    const formatData = CurrentYearData?.flatMap((ele) =>
      ele?.grades?.map((grade) => ({
        Branch: ele?.branch_name,
        Grade: grade?.grade_name,
        "Term 1": grade?.terms?.Term1,
        "Term 2": grade?.terms?.Term2,
        "Term 3": grade?.terms?.Term3,
        "Term 4": grade?.terms?.Term4,
      }))
    );
    ExportToExcel(formatData, "current_year_student_report");
  };
  let rowIndex = 1;
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Student Enrollment - Total Summary
              </h4>
            {CurrentYearData?.length > 0 && (
              <div className="download_icon" onClick={() => exportExsl()}>
                <i class="fa-solid fa-download"></i>
              </div>
            )}
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr no.</th>
                  <th>Branch</th>
                  <th>Grade</th>
                  <th>Term 1</th>
                  <th>Term 2</th>
                  <th>Term 3</th>
                  <th>Term 4</th>
                </tr>
              </thead>
              <tbody>
                {CurrentYearData &&
                  CurrentYearData?.map((elem, i) => {
                    return (
                      <>
                        {elem?.grades?.map((grade) => {
                          return (
                            <>
                              <tr>
                                <td>{rowIndex++}</td>
                                <td>{elem?.branch_name}</td>
                                <td>{grade?.grade_name}</td>
                                <td>{grade?.terms?.Term1}</td>
                                <td>{grade?.terms?.Term2}</td>
                                <td>{grade?.terms?.Term3}</td>
                                <td>{grade?.terms?.Term4}</td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                <tr>
                  <td colSpan="3" className="text-center">
                    <b>Total</b>
                  </td>
                  {["Term1", "Term2", "Term3", "Term4"].map((term) => {
                    const total = CurrentYearData?.reduce(
                      (total, elem) =>
                        total +
                        elem.grades?.reduce(
                          (gradeTotal, grade) =>
                            gradeTotal + (grade.terms?.[term] || 0),
                          0
                        ),
                      0
                    );
                    return <td key={term}>{total ? total : 0}</td>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default StudentTotalEnrollment;
