import React, { useState, useEffect } from "react";
import { get_states } from "../../../../apis/Common";
import { state_branch_all } from "../../../../apis/Branches";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { course_all } from "../../../../apis/Course";
import StudentTotalEnrollment from "./StudentTotalEnrollment";
import StudentYearComparison from "./StudentYearComparison";
import ClassTotalEnrollment from "../ClassManagment/ClassTotalStudents";
const EnromentSummary = () => {
  const location = useLocation();
  const path = location?.pathname === "/admin/reports/class-enrollment-summary";
  const {
    register,
    setValue,
    formState: { errors, isDirty },
    watch,
  } = useForm();
  const course_id = watch("course_id");
  const state = watch("state");
  const branch_id = watch("branch");
  const [statelist, setStateList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const savedComparison = localStorage.getItem('comparison') || 'no';
  const [comparison, setComparison] = useState(savedComparison);
  useEffect(() => {
    const fetchData = async () => {
      const stateList = await get_states();
      const courseData = await course_all();
      setStateList(stateList?.data);
      setCourseList(courseData?.data);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      const data = await state_branch_all(state);
      setBranchList(data?.data);
    };
    if (state) {
      getData();
    }
  }, [state]);
  useEffect(()=>{
  const onLocationChange=()=>{
    setValue('state','');
    setValue('branch','');
    setValue('course_id','')
  }
  onLocationChange();
  },[location])
  const handleComparisonChange = (value) => {
    setComparison(value);
    localStorage.setItem('comparison', value);
  };
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              <div className="col-md-4">
                <h4 className="modal-title">
                  {path ? "Class" : "Student"} Enrollment Summary
                </h4>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h5>Current Year Enrollment Summary</h5>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>State </label>
                        <select
                          className="form-select form-control"
                          {...register("state")}
                        >
                          {statelist?.length > 0
                            ? [
                              <option value="">Choose State</option>,
                              statelist?.map((elem) => {
                                return (
                                  <option value={elem?.id}>
                                    {elem?.name}
                                  </option>
                                );
                              }),
                            ]
                            : [<option value="">State not available</option>]}
                        </select>
                      </div>
                    </div>
                    <div className={`col-md-3`}>
                      <div className="form-group">
                        <label>Branch</label>
                        <select
                          className="form-select form-control"
                          name="branch"
                          {...register("branch")}
                        >
                          {branchlist?.length > 0
                            ? [
                              <option value="">Choose Branch</option>,
                              branchlist?.map((elem) => {
                                return (
                                  <option value={elem?.id}>
                                    {elem?.branch_name}
                                  </option>
                                );
                              }),
                            ]
                            : [
                              <option value="">
                                Branch not available
                              </option>,
                            ]}
                        </select>
                      </div>
                    </div>
                    <div className={`col-md-3`}>
                      <div className="form-group">
                        <label>Course</label>
                        <select
                          className="form-select form-control"
                          name="course"
                          {...register("course_id")}
                        >
                          {courseList?.length > 0
                            ? [
                              <option value="">Choose Course</option>,
                              courseList?.map((elem) => {
                                return (
                                  <option value={elem?.id}>
                                    {elem?.name}
                                  </option>
                                );
                              }),
                            ]
                            : [
                              <option value="">Course not available</option>,
                            ]}
                        </select>
                      </div>
                    </div>
                    {!path && (
                      <div className={`col-md-3`}>
                        <label className="student-enrollment_comparison">Comparison *</label>
                        <div className="checkbox student-enrollment_checkbox">
                          <label>
                            <input
                              type="radio"
                              name="comparison"
                              id="enrolled"
                              value="yes"
                              onChange={() => handleComparisonChange('yes')}
                              checked={comparison === 'yes'}
                            />{" "}
                            Yes{" "}
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="comparison"
                              id="enrolled"
                              value="no"
                              onChange={() => handleComparisonChange('no')}
                              checked={comparison === 'no'}
                            />{" "}
                            No{" "}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!path
                ? comparison === 'no'
                  ? <StudentTotalEnrollment course_id={course_id} state={state} branch_id={branch_id} />
                  : <StudentYearComparison course_id={course_id} state={state} branch_id={branch_id} />
                : <ClassTotalEnrollment course_id={course_id} state={state} branch_id={branch_id} />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnromentSummary;
