import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { branch_edit, branch_update } from '../../../api/branches/Branch';

const EditBrance = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
const branch_id=localStorage.getItem('b_id')
    const navigate = useNavigate();
    const fetchData = async () => {
        let res = await branch_edit(branch_id);
        setValue('branch_code', res?.data?.branch_code);
        setValue('branch_name', res?.data?.branch_name);
        setValue('branch_addresss', res?.data?.branch_addresss);
        setValue('Branch_contact_number', res?.data?.Branch_contact_number);
        setValue('api_key', res?.data?.api_key);
        setValue('secret_key', res?.data?.secret_key);
        setValue('email',res?.data?.email)
    }
    useEffect(() => {
        fetchData();
    }, []);

    const onSubmit = async (data) => {
        let result = await branch_update(branch_id, data);
        if (result.status) {
            return navigate('/branch/settings/edit');
        }
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Branch</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Branch Info</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Paypal Payment Informtion</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Branch Code</labell>
                                            <input type="text" className="form-control"
                                                {...register('branch_code', { required: true })}
                                            disabled/>
                                        {errors.branch_code && errors.branch_code.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Branch Name</labell>
                                            <input type="text" className="form-control"
                                                {...register('branch_name', { required: true })}
                                            disabled/>
                                        {errors.branch_name && errors.branch_name.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Branch Addresss</labell>
                                            <input type="text" className="form-control"
                                                {...register('branch_addresss', { required: true })}
                                            disabled/>
                                        {errors.branch_addresss && errors.branch_addresss.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Branch Contact Number</labell>
                                            <input type="text" className="form-control"
                                                {...register('Branch_contact_number', { required: true })}
                                            disabled/>
                                        {errors.Branch_contact_number && errors.Branch_contact_number.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Api key</labell>
                                            <input type="text" className="form-control"
                                                {...register('api_key')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Secret key</labell>
                                            <input type="text" className="form-control"
                                                {...register('secret_key')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditBrance ;
