import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader.jsx";
import { formatDate, getUserPermission } from "../../../../Utils/Utils.js";
import { free_student_list } from "../../../api/Student_Enroll.jsx";
import CustomTable from "../../pages/CustomTable";

import { member_destroy } from "../../../../apis/studentManagement/StudentManagement";
const Free_student_list = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permission = getUserPermission("Students");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Name",
      selector: "first_name",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: true,
    },
    {
      name: "Branch",
      selector: "branch",
      sortable: true,
    },
    {
      name: "Class",
      selector: "class_name",
      sortable: true,
    },
    {
      name: "Sign In Date",
      selector: "date",
      sortable: true,
    },
    {
      name: "Status",
      selector: "delete_status",
      sortable: true,
    },
    permission?.edit === 1 || permission?.delete === 1 || permission?.view === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const branch_id = localStorage.getItem("b_id");
      const result = await free_student_list(branch_id);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          first_name,
          last_name,
          subject,
          branch_name,
          grade_name,
          user_id,
          delete_status,
          created_at,
          class_name
        } = elem;
        return {
          no: `#${index + 1}`,
          first_name:
            first_name?.toLowerCase() + " " + last_name?.toLowerCase(),
          grade: `Year ${grade_name}`,
          subject: subject ? subject : "-",
          branch: branch_name,
          class_name:class_name,
          date: formatDate(created_at),
          delete_status: delete_status == "1" ? "Inactive" : "Active",
          action: (
            <>
              <div data-tag="allowRowEvents">
                {permission?.view === 1 ? (
                  <Link
                    className="edit-btn"
                    to={`/branch/free/student_enroll-view/${id}/free`}
                  >
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </Link>
                ) : (
                  ""
                )}
                {permission?.edit === 1 ? (
                  <Link
                    className="edit-btn"
                    to={`/branch/free/member-edit-free/${user_id}`}
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </Link>
                ) : (
                  ""
                )}
              </div>
              {permission?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() => destroy(user_id, delete_status === 0 ? 0 : 1)}
                >
                  {delete_status === 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}{" "}
                </button>
              ) : (
                ""
              )}
            </>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, data) => {
      const dataa = {
        delete_status: data,
      };
      const result = window.confirm(
        `${
          data === 0
            ? "Are you sure you want to inactive this user ?"
            : "Are you sure you want to active this user ? "
        }`
      );
      if (result) {
        const res = await member_destroy(id, dataa);
        console.log("res", res);
        if (res?.data) {
          get_data();
        }
      }
      get_data();
    };
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Free Student List</h4>
            </div>
            <div className="col-md-8">
              {permission?.add === 1 ? (
                <Link className="ui button float-end" to="/branch/free/member-add">
                  Add Student
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default Free_student_list;
