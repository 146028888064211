import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Dashboard from "../pages/Dashboard";

export const checkActive = (location, paths) => {
  return paths.some((path) => location.pathname.startsWith(path));
};

const Sidebar = () => {
  // accordion-header active
  const [tabActive, setTabActive] = useState({
    generalSetting: false,
    administratorSettings: false,
    marketSupporting: false,
    studentManagement: false,
    reports: false,
    audit: false,
    courseManagment: false,
    testManage: false,
    teacherManage: false,
    categories: false,
    assesment: false,
    dashboard: false,
    state: false,
    activityManage: false,
  });

  const location = useLocation();
  const isDashboard = location.pathname === "/admin/dashboard";

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("general-setting")) {
      setTabActive({ generalSetting: true });
    }
    if (currentPath.includes("administrator-setting")) {
      setTabActive({ administratorSettings: true });
    }
    if (currentPath.includes("marketing-support")) {
      setTabActive({ marketSupporting: true });
    }
    if (currentPath.includes("course-managment")) {
      setTabActive({ courseManagment: true });
    }
    if (currentPath.includes("students")) {
      setTabActive({ studentManagement: true });
    }
    if (currentPath.includes("audit")) {
      setTabActive({ audit: true });
    }
    if (currentPath.includes("test-management")) {
      setTabActive({ testManage: true });
    }
    if (currentPath.includes("teachers-management")) {
      setTabActive({ teacherManage: true });
    }
    if (currentPath.includes("deleted-records")) {
      setTabActive({ activityManage: true });
    }
    if (currentPath.includes("dashboard")) {
      setTabActive({ dashboard: true });
    }
    if (currentPath.includes("reports")) {
      setTabActive({ reports: true });
    }
  }, [location]);

  return (
    <div id="layoutSidenav_nav">
      <NavLink className="left-main-heading" to="/admin/dashboard">
        {" "}
        <i className="fa-solid fa-table-cells-large"></i> Dashboard
      </NavLink>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.generalSetting ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsetrhee"
              aria-expanded="false"
              aria-controls="collapsetrhee"
            >
              <i className="fa-solid fa-gears"></i> Admin Settings
            </button>
          </h2>
          <div
            id="collapsetrhee"
            className={`accordion-collapse collapse ${
              tabActive?.generalSetting ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <ul>
                  <NavLink
                    to="/admin/general-setting/grades/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/grade"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Grades
                  </NavLink>
                  {/* <NavLink
                    to="/admin/general-setting/term/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/term"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Terms
                  </NavLink> */}
                  <NavLink
                    to="/admin/general-setting/day/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/day"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Days
                  </NavLink>
                  <NavLink
                    to="/admin/general-setting/subjects"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/subject"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Subjects
                  </NavLink>
                  <NavLink
                    to="/admin/general-setting/category/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/category"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Course Type
                  </NavLink>
                  <NavLink
                    to="/admin/general-setting/discount/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/discount"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Discounts
                  </NavLink>
                  <NavLink
                    to="/admin/general-setting/Categories/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/Categories"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Category
                  </NavLink>

                  <NavLink
                    to="/admin/general-setting/sub-category/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/sub-category"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Sub Category
                  </NavLink>
                  <NavLink
                    to="/admin/general-setting/test-type/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/general-setting/test-type"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Test type
                  </NavLink>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.administratorSettings ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsetrhee1"
              aria-expanded="false"
              aria-controls="collapsetrhee1"
            >
              <i className="fa-solid fa-gears"></i> Administrator Settings
            </button>
          </h2>
          <div
            id="collapsetrhee1"
            className={`accordion-collapse collapse ${
              tabActive?.administratorSettings ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <ul>
                  <NavLink
                    to="/admin/administrator-setting/cse-brances"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/administrator-setting/add"
                        ) ||
                        location.pathname.startsWith(
                          "/admin/administrator-setting/edit"
                        ) ||
                        isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Branches
                  </NavLink>

                  <NavLink
                    to="/admin/administrator-setting/websiteEnv/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/administrator-setting/websiteEnv"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Website ENV
                  </NavLink>
                  <NavLink
                    to="/admin/administrator-setting/sub-users/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/administrator-setting/sub-users"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Users
                  </NavLink>
                  <NavLink
                    to="/admin/administrator-setting/role-permission/all"
                    className="nav-link"
                  >
                    Roles and Permissions
                  </NavLink>
                  <NavLink
                    to="/admin/administrator-setting/events"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/administrator-setting/event"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Events
                  </NavLink>
                  <NavLink
                    to="/admin/administrator-setting/bookings"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/administrator-setting/bookings"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Events Bookings
                  </NavLink>
                  <NavLink
                    to="/admin/administrator-setting/state/all"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/administrator-setting/state"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    State
                  </NavLink>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.studentManagement ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <i className="fa-solid fa-graduation-cap"></i> Students Management
            </button>
          </h2>
          <div
            id="collapseTwo"
            className={`accordion-collapse collapse ${
              tabActive?.studentManagement ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <ul>
                  <NavLink
                    to="/admin/students/members"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/students/member"
                        ) ||
                        location.pathname.startsWith(
                          "/admin/students/add-member"
                        ) ||
                        location.pathname.startsWith(
                          "/admin/students/student_enroll_view"
                        ) ||
                        isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Students
                  </NavLink>
                  <NavLink
                    to="/admin/students/attendance"
                    className={({ isActive }) => {
                      const isActivePath =
                        location.pathname.startsWith(
                          "/admin/students/attendance"
                        ) || isActive;
                      return isActivePath ? "nav-link active" : "nav-link";
                    }}
                  >
                    Attendance
                  </NavLink>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.courseManagment ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              <i className="fa-solid fa-chalkboard-user"></i> Course Management
            </button>
          </h2>
          <div
            id="collapseTen"
            className={`accordion-collapse collapse ${
              tabActive?.courseManagment ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink
                  to="/admin/course-managment/course/all"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith(
                        "/admin/course-managment/course"
                      ) || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Course
                </NavLink>
                <NavLink
                  to="/admin/course-managment/assesment/all-free"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith(
                        "/admin/course-managment/free-assesment"
                      ) || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Free Assessment Test
                </NavLink>
                <NavLink
                  to="/admin/course-managment/selective-trial-test/all"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith(
                        "/admin/course-managment/selective-trial-test"
                      ) || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Selective Trial Test
                </NavLink>
                <NavLink
                  to="/admin/course-managment/text-book/all"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith(
                        "/admin/course-managment/text-book"
                      ) || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Text Books
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.testManage ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              <i className="fa-solid fa-file-lines"></i> Test Management
            </button>
          </h2>
          <div
            id="collapseEleven"
            className={`accordion-collapse collapse ${
              tabActive?.testManage ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink
                  to="/admin/test-management/questionaire-all"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith(
                        "/admin/test-management/questionaire"
                      ) || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Questionnaire
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.teacherManage ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseteacher"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              <i className="fa-solid fa-calendar-days"></i> Teachers Management
            </button>
          </h2>
          <div
            id="collapseteacher"
            className={`accordion-collapse collapse ${
              tabActive?.teacherManage ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink
                  to="/admin/teachers-management/all"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith("/admin/teachers-management") || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Teachers
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${tabActive?.audit ? "active" : ""}`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              <i className="fa-solid fa-clipboard-check"></i> Audit
            </button>
          </h2>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${tabActive?.reports ? "active" : ""}`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#reports"
              aria-expanded="false"
              aria-controls="collapseNine1"
            >
              <i className="fa-solid fa-calendar-days"></i>Reports
            </button>
          </h2>
          <div
            id="reports"
            className={`accordion-collapse collapse ${
              tabActive?.reports ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink
                  to="/admin/reports/student-enrollment-summary"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith("/admin/reports/student-enrollment-summary") ||
                      isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Student Management
                </NavLink>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink
                  to="/admin/reports/class-enrollment-summary"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith("/admin/reports/class-enrollment-summary") ||
                      isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Class Management
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.marketSupporting ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              <i className="fa-solid fa-clipboard-user"></i> Marking &
              Supporting
            </button>
          </h2>
        </div>
        <div className="accordion-item">
          <h2
            className={`accordion-header ${
              tabActive?.activityManage ? "active" : ""
            }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse1"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              <i className="fa-solid fa-calendar-days"></i> Activity Log
            </button>
          </h2>
          <div
            id="collapse1"
            className={`accordion-collapse collapse ${
              tabActive?.activityManage ? "show" : ""
            }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink
                  to="/admin/deleted-records/all"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith("/admin/deleted-records") ||
                      isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Deleted Records
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="text-capitalize">Logged in as:</div>
          <span className="text-lowercase">admin@gmail.com</span>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
