import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { branch_all, role_by_branch } from "../../../../apis/Branches";
import { handleNavigation } from "../../../../Utils/Utils";
import { sub_users_edit , sub_users_update } from "../../../api/user";

const EditUsers = () => {
  const [rolelist, setRoleList] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const branchid = localStorage.getItem("b_id");
  const branchName = JSON.parse(localStorage.getItem("b_name"));
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = useForm({mode:"onChange"});

  const id = useParams();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    data.branch_id = branchid;
    let result = await sub_users_update(id, data);
    if (result.status) {
      return navigate("/branch/settings/users/all");
    }
    reset();
  };
  const fetchData = async () => {
    let res = await sub_users_edit(id?.id);
    setValue("first_name", res?.data[0]?.first_name);
    setValue("last_name", res?.data[0]?.last_name);
    setValue("mobile", res?.data[0]?.mobile);
    setValue("status", res?.data[0]?.status.toString());
    setValue("email", res?.data[0]?.email);
    const roleList = await role_by_branch(branchid);
    setRoleList(roleList?.data);
    setTimeout(() => {
      setValue('role_id',res?.data[0]?.role_id);
    }, 1000);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleSelectRole = (id) => {
    setSelectedRoleId(Number(id));
    let selected =
      rolelist && rolelist.find((item) => item.role_id === Number(id));
    if (selected) {
      setValue("role_id", selected.role_id);
      setValue("role", selected.role);
    }
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-users">
              <h4 className="modal-title">Edit User</h4>
              <Link
                className="edit-btn"
                to={`/branch/settings/users/all`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="permission-sec-n">
              {/* <h4 className="modal-title">Permissions</h4> */}
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>First Name *</label>
                            <input
                              type="text"
                              placeholder="Enter User First Name"
                              className="form-control"
                              {...register("first_name", { required: true })}
                            />
                            {errors.first_name &&
                              errors.first_name.type === "required" && (
                                <span className="validation-error">
                                  This is required
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Last Name *</label>
                            <input
                              type="text"
                              placeholder="Enter User Last Name"
                              className="form-control"
                              {...register("last_name", { required: true })}
                            />
                            {errors.last_name &&
                              errors.last_name.type === "required" && (
                                <span className="validation-error">
                                  This is required
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email *</label>
                            <input
                              type="email"
                              placeholder="Enter User Email"
                              className="form-control"
                              {...register("email", { required: true })}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <span className="validation-error">
                                  This is required
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Mobile No *</label>
                            <div className="input-group branch-prepend">
                              <div className="input-group-prepend">
                                <div className="input-group-text">+61</div>
                              </div>
                              <input
                                type="text"
                                placeholder="04XX XXX XXX"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                className="form-control"
                                {...register("mobile", {
                                  required: "Mobile number  is required",
                                  pattern: {
                                    value: /^0[2-8]\d{8}$|^04\d{2}\s\d{3}\s\d{3}$/,
                                    message:
                                      "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX)",
                                  },
                                  validate: {
                                    isNumeric: (value) =>
                                      value === "" ||
                                      /^\d+$/.test(value.replace(/\s+/g, "")) ||
                                      "Phone number must contain only numbers",
                                  },
                                })}
                                maxLength={10}
                              />
                            </div>
                            {errors.mobile && (
                              <span className="validation-error">
                                {errors.mobile.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label>Branch *</label>
                            <input
                              className="form-control"
                              type="text"
                              value={branchName}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group  add_new">
                            <label>Roles *</label>
                            <Link
                              href=""
                              title="Add new role"
                              className="add_role"
                            >
                              Add new role
                            </Link>
                            <select
                              className="form-select"
                              {...register("role_id", { required: "true" })}
                              value={selectedRoleId}
                              onChange={(e) => handleSelectRole(e.target.value)}
                            >
                              {rolelist?.length > 0
                                ? [
                                    <option value="">Choose Role</option>,
                                    rolelist?.map((elem) => {
                                      return (
                                        <option value={elem?.role_id}>
                                          {elem?.role}
                                        </option>
                                      );
                                    }),
                                  ]
                                : [
                                    <option value="">
                                      Role not available
                                    </option>,
                                  ]}
                            </select>
                            {errors.role_id &&
                              errors.role_id.type === "required" && (
                                <span className="validation-error">
                                  This is required
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label>Status *</label>
                            <select
                              className="form-select"
                              {...register("status")}
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                            {errors.status &&
                              errors.status.type === "required" && (
                                <span className="validation-error">
                                  This is required
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditUsers;
