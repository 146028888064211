import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { course_details, course_edit } from "../../../api/Course/Course";
import { useForm } from "react-hook-form";
import { handleNavigation } from "../../../../Utils/Utils";

const DetailsCourse = () => {
  const [course, setCourse] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    formState: {isDirty},
  } = useForm();
  const fetchData = async () => {
    let res = await course_details(id);
    if (res?.data && res.data.length > 0) {
      setCourse(res.data[0]);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Course Details</h4>
          <Link
            className="edit-btn"
            to={`/branch/course/all`}
            onClick={(e) => {
              handleNavigation(e, isDirty);
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="form-horizontal">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Grade</label>
                      <p>
                        {course.grade_id === 0
                          ? `Grade ${course.grade_id} (kindy)`
                          : `Grade ${course.grade_id}`}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Term</label>
                      <p>{course.term_id}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Course Type</label>
                      <p>{course.course_type_id}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Course Name</label>
                      <p>{course.name}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Unit Price</label>
                      <p>{course.price}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Status</label>
                      <p>{course.status === 0 ? "Inactive" : "Active"}</p>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Term</label>
                                                <p>{questionnaireDatail?.term_id ? `Term ${questionnaireDatail?.term_id}` : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Course Type</label>
                                                <p>{questionnaireDatail?.category_name ? ` ${questionnaireDatail?.category_name}` : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <p>{questionnaireDatail?.subject ? questionnaireDatail?.subject : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Time</label>
                                                <p>{questionnaireDatail?.time ? questionnaireDatail?.time : '-'} mins</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Date</label>
                                                <p>{formattedDate ? formattedDate : '-'}</p>
                                            </div>
                                        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCourse;
