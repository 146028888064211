import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { course_add } from "../../../apis/Course";
import { Button } from "semantic-ui-react";
import { get_term_available_units } from "../../../apis/Common";
import { toast } from "react-toastify";
export const AddTerm = ({
  setIsModalOpen,
  isModalOpen,
  allTerms,
  selectedCourseId,
  selectedTerms,
}) => {
  const [allFilteredTerms, setAllFilteredTerms] = useState([]);
  const [totalUnits, setTotalUnits] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const term = watch("term_id");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const units = await get_term_available_units(term);
      const maxWeek = units?.data[0]?.max_week || 0;
      setTotalUnits(maxWeek);
      setValue("totalUnits", maxWeek, { shouldValidate: true });
    };
    if (term) {
      fetchData();
    }
  }, [term, setValue]);
  const handleInputChange = (inputValue) => {
    setTotalUnits(totalUnits);
    const value = Number(inputValue);
    if (isNaN(value)) {
      toast.error("Invalid input. Please enter a valid number.");
      return;
    }
    if (value > totalUnits) {
      setValue("totalUnits", totalUnits);
      toast.error(`The units cannot be greater than ${totalUnits}`);
    } else if (value === 0) {
      // setTotalUnits("")
      setValue("totalUnits", "");
      toast.error(`The units should in between 0 to ${totalUnits} `);
      return;
    }
  };
  useEffect(() => {
    let filteredAll = allTerms?.filter(
      (itemAll) => !selectedTerms?.map((item) => item.id).includes(itemAll.id)
    );
    setAllFilteredTerms(filteredAll);
  }, [selectedCourseId, isModalOpen, allTerms]);

  const onSubmit = async (data) => {
    const form = new FormData();
    form.append("course_id",selectedCourseId);
    form.append("totalUnits",getValues('totalUnits'));
    form.append("price",getValues('price'));
    form.append("term_id",getValues('term_id'));
    let result = await course_add(form);
    if (result.status) {
      setIsModalOpen(false);
      localStorage.setItem("course_id", selectedCourseId);
      localStorage.setItem("totalUnits",getValues('totalUnits'));
      localStorage.setItem("course_type", result.data?.course_type);
      localStorage.setItem("term_id", result.data?.term_id);

      navigate("/admin/course-managment/course/add/term", {
        state: { currentStep: 2, learningStep: 1 },
      });
    }
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      className="modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="modal-overlay"
      contentLabel=" Modal"
    >
      <div className="modal-header">
        <h1 className="modal-title fs-5">Add Term</h1>
        <button
          type="button"
          onClick={() => {
            setIsModalOpen(false);
            reset();
          }}
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <form className="modal-body" onSubmit={handleSubmit(onSubmit)}>
        <div className="terms selectbox">
          <label>Terms *</label>
          <select
            className="form-select form-control"
            {...register("term_id", { required: true })}
          >
            <option value="">CHOOSE TERM</option>
            {allFilteredTerms?.map((elem) => (
              <option key={elem.id} value={elem.id}>
                {elem.name}
              </option>
            ))}
          </select>
          {errors.term_id && errors.term_id.type === "required" && (
            <span className="validation-error">This is required</span>
          )}
        </div>
        <br />
        <div className="terms">
          <div className="form-group">
            <label>Total Units *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter total units"
              {...register("totalUnits", {
                required: "This field is required",
                validate: (value) =>
                  value > 0 || "Total units must be greater than 0",
              })}
              onKeyPress={(event) => {
                if (!/[\d]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onInput={(e) => handleInputChange(e.target.value)}
              disabled
            />
            {errors.totalUnits && (
              <span className="validation-error">
                {errors.totalUnits.message}
              </span>
            )}
          </div>
        </div>
        <br />
        <div className="terms">
          <label>Unit Price *</label>
          <input
            type="text"
            className="form-control"
            placeholder="Unit Price"
            {...register("price", { required: true })}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          {errors.price && errors.price.type === "required" && (
            <span className="validation-error">This is required</span>
          )}
        </div>
        <div className="modal-footer">
          <Button
            type="button"
            className="btn button"
            onClick={() => {setIsModalOpen(false);reset()}}
          >
            Close
          </Button>
          <Button type="submit" className="btn button">
            Save changes
          </Button>
        </div>
      </form>
    </Modal>
  );
};
