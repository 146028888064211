import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';

export async function branch_login(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branches-login', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            localStorage.setItem("userData", JSON.stringify(responseData));
            localStorage.setItem("email", JSON.stringify(responseData.data.email));
            localStorage.setItem("b_name", JSON.stringify(responseData?.data?.b_name));
            localStorage.setItem("b_id", JSON.stringify(responseData?.data?.b_id));
            localStorage.setItem("user_id", JSON.stringify(responseData?.data?.id));
            localStorage.setItem("fullname", responseData?.data?.fullname);
            localStorage.setItem("role", responseData?.data?.role);
            var now = new Date().getTime();
            localStorage.setItem('setupTime', now);
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
