import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { handleNavigation } from "../../../../Utils/Utils";
import { get_course_all_year_data } from "../../../api/common";
import {
  get_active_class_grades,
  get_active_class_terms,
  get_class_courses,
  class_update,
  class_edit,
} from "../../../api/Class";
const EditClass = () => {
  const [yearData, setYearData] = useState([]);
  const [gradData, setGradeData] = useState([]);
  const [termData, setTermData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const branchid = localStorage.getItem("b_id");
  // const branchName = JSON.parse(localStorage.getItem("b_name"));
  const userData =JSON.parse(localStorage.getItem("userData"));
  const id = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
  });
  const grade_id = watch("grade");
  const term_id = watch("term");
  const year_id = watch("year");
  const navigate = useNavigate();
  const fetchCourse = async (grade_id, term_id, year_id) => {
    const courseList = await get_class_courses(grade_id, term_id, year_id);
    setCourseData(courseList?.data);
  };
  useEffect(() => {
    const fetchData = async () => {
      const YearList = await get_course_all_year_data();
      const gradeList = await get_active_class_grades();
      setYearData(YearList?.data);
      setGradeData(gradeList?.data);
      let res = await class_edit(id?.id);
      const termList = await get_active_class_terms(res?.data[0]?.year,userData?.data?.branchstate);
      setTermData(termList?.data);
      setValue("year", res?.data[0]?.year);
      setValue("grade", res?.data[0]?.grade);
      setValue("branch_id", res?.data[0]?.branch_id);
      setValue("class_description", res?.data[0]?.class_description);
      setValue("class_name", res?.data[0]?.class_name);
      setValue("class_timing", res?.data[0]?.class_timing);
      setValue("delete_status", res?.data[0]?.delete_status);
      setValue("instructor_name", res?.data[0]?.instructor_name);
      setTimeout(() => {
        setValue("term", res?.data[0]?.term);
        setValue("course_id", res?.data[0]?.course_id);
      }, 700);
      fetchCourse(res?.data[0]?.grade, res?.data[0]?.term, res?.data[0]?.year);
    };
    fetchData();
  }, [id]);
  useEffect(() => {
    if (grade_id && term_id && year_id) {
      fetchCourse(grade_id, term_id, year_id);
    }
    const getData = async () => {
      const termList = await get_active_class_terms(year_id,userData?.data?.branchstate);
      setTermData(termList?.data);
    };
    getData();
  }, [grade_id, term_id, year_id,userData?.data?.branchstate]);
  const onSubmit = async (data) => {
    data.branch_id = branchid;
    let result = await class_update(data, id?.id);
    if (result.status) {
      return navigate("/branch/class-management/all");
    }
    // reset();
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-users">
              <h4 className="modal-title">Edit Class</h4>
              <Link
                className="edit-btn"
                to={`/branch/class-management/all`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="permission-sec-n">
              {/* <h4 className="modal-title">Permissions</h4> */}
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Class Name *</label>
                          <input
                            type="text"
                            placeholder="Enter Class Name"
                            className="form-control"
                            {...register("class_name", { required: true })}
                          />
                          {errors.class_name &&
                            errors.class_name.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Year *</label>
                          <select
                            className="form-select"
                            {...register("year", { required: "true", onChange: () => {
                              setValue("term", "");
                              setValue("course_id", "");
                            },})}
                          >
                            {yearData?.length > 0
                              ? [
                                  <option value="">Choose Year</option>,
                                  yearData?.map((elem) => {
                                    return (
                                      <option value={elem?.current_year}>
                                        {elem?.current_year}
                                      </option>
                                    );
                                  }),
                                ]
                              : [<option value="">Years not available</option>]}
                          </select>
                          {errors.year && errors.year.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Grade *</label>
                          <select
                            className="form-select"
                            {...register("grade", {
                              required: "true",
                              onChange: () => {
                                setValue("course_id", "");
                              },
                            })}
                          >
                            {gradData?.length > 0
                              ? [
                                  <option value="">Choose Grade</option>,
                                  gradData?.map((elem) => {
                                    return (
                                      <option value={elem?.id}>
                                        Year {elem?.name}
                                      </option>
                                    );
                                  }),
                                ]
                              : [
                                  <option value="">
                                    Grades not available
                                  </option>,
                                ]}
                          </select>
                          {errors.grade && errors.grade.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Term *</label>
                          <select
                            className="form-select"
                            {...register("term", { required: "true" ,onChange: () => {
                              setValue("course_id", "");
                            },})}
                          >
                            {termData?.length > 0
                              ? [
                                  <option value="">Choose Term</option>,
                                  termData?.map((elem) => {
                                    return (
                                      <option value={elem?.id}>
                                        {elem?.name}
                                      </option>
                                    );
                                  }),
                                ]
                              : [<option value="">Terms not available</option>]}
                          </select>
                          {errors.term && errors.term.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Course *</label>
                          <select
                            className="form-select"
                            {...register("course_id", { required: "true" })}
                          >
                            {courseData?.length > 0
                              ? [
                                  <option value="">Choose Course</option>,
                                  courseData?.map((elem) => {
                                    return (
                                      <option value={elem?.id}>
                                        {elem?.name}
                                      </option>
                                    );
                                  }),
                                ]
                              : [
                                  <option value="">
                                    Course not available
                                  </option>,
                                ]}
                          </select>
                          {errors.course_id &&
                            errors.course_id.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Class Timing *</label>
                          <select
                            className="form-select"
                            {...register("class_timing", { required: "true" })}
                          >
                            <option value="morning">Morning</option>
                            <option value="afternoon">Afternoon</option>
                          </select>
                          {errors.class_timing &&
                            errors.class_timing.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            name="descriptions"
                            placeholder="ENTER DESCRIPTION"
                            rows="3"
                            {...register("class_description")}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Instructor Name</label>
                          <input
                            type="text"
                            placeholder="Enter Instructor Name "
                            className="form-control"
                            {...register("instructor_name")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditClass;
