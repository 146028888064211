import axios from "axios";
import { AddTerm } from "./TermModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import DataLoader from "../../../Utils/DataLoader";
import { HTTPURL } from "../../../constants/Matcher";
import { get_terms_by_year } from "../../../apis/Common";
import CustomTableWithChild from "../CustomTableWithChild";
import { getUserPermission } from "../../../Utils/Utils";
import { DuplicateCourse } from "./DuplicateCourse";
import { courses_all_data, course_destroy } from "../../../apis/Course";
const AllCourses = () => {
  const [showResults, setShowResults] = useState(false);
  const [openDublicateModal, setOpenDublicateModal] = useState(false);
  const [dublicateData, setDublicateData] = useState({ course_id: "", year: "", grade: "", selectedTermData: [] })
  const [Course, setCourse] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allTerms, setAllTerms] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const permissions = getUserPermission("Course");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns2 = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Course",
      selector: "name",
      sortable: true,
    },
    {
      name: "Year",
      selector: "year",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
        name: "Action",
        selector: "action",
      }
      : "",
  ];
  const handleDeleteTerm = async (courseId, termId) => {
    try {
      let responseData = await axios({
        method: "GET",
        url:
          HTTPURL + `api/course-term-distroy?term_id=${termId}&id=${courseId}`,
      });

      get_data();
      if (responseData.status) {
        toast.success(responseData?.message);
      } else {
        toast.error(responseData?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  let childColumns = [
    {
      name: "S.No",
      selector: (_, index) => index + 1,
      sortable: true,
    },
    {
      name: "Term",
      selector: (row) => row.name,
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
        name: "Action",
        name: "Action",
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/admin/course-managment/course/edit/${row.course_id}/${row.id}/${row.course_type_id}/${row?.grade_id}/${row?.year}`}
                  state={{
                    currentStepActive: 2,
                    isNotVisibleFirstStep: true,
                  }}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button
                  class="dlt-btn"
                  onClick={() => {
                    handleDeleteTerm(row.course_id, row.id);
                  }}
                >
                  {" "}
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              ) : (
                ""
              )}
            </div>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
      : "",
  ];

  const get_data = async () => {
    const result = await courses_all_data();
    const arrayData = result?.data?.map((elem, index) => {
      const { id, name, status, year, grade, terms, course_type, termdata } = elem;
      return {
        no: `#${index + 1}`,
        name: name,
        status: status == "0" ? "INACTIVE" : "ACTIVE",
        year: year,
        grade: grade,
        terms: terms,
        action: (
          <div data-tag="allowRowEvents">
            {permissions?.edit === 1 ? (
              <Link
                class="edit-btn"
                to={`/admin/course-managment/course/edits/${id}`}
                state={{
                  currentStepActive: 1,
                  isNotVisibleSecondStep: true,
                }}
              >
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </Link>
            ) : (
              ""
            )}
            {permissions?.delete === 1 ? (
              <button
                class="dlt-btn"
                onClick={() => destroy(id, status == "1" ? 0 : 1, name)}
              >
                {" "}
                {status == 0 ? (
                  <i class="fa-solid fa-check"></i>
                ) : (
                  <i class="fa fa-trash" aria-hidden="true"></i>
                )}
              </button>
            ) : (
              ""
            )}
            <button class="dlt-btn" onClick={() => {
              setOpenDublicateModal(true); setDublicateData({
                course_id: id, year: year, grade: grade, selectedTermData: termdata
              })
            }}>
              <i class="fa-solid fa-copy"></i>
            </button>
            {permissions?.add === 1 ? (
              <button
                class="dlt-btn term-add"
                onClick={() => {
                  handleAddTermClick(id, terms, course_type, year);
                }}
              >
                {" "}
                Add Term
              </button>
            ) : (
              ""
            )}
          </div>
        ),
      };
    });

    setCourse(arrayData, setShowResults(true));
  };
  const destroy = async (id, status, name) => {
    const data = { status: status, title: name, deleted_by: UserID };
    const result = window.confirm(
      `${status === 1
        ? "Are you sure you want to delete  this Course ?"
        : "Are you sure you want to  delete  this Course ? "
      }`
    );
    if (result) {
      await course_destroy(id, data);
    }
    get_data();
  };
  useEffect(() => {
    localStorage.removeItem("CourseEditType");
    get_data();
  }, [openDublicateModal]);

  const handleAddTermClick = async (id, alreadySelectedTerms, course_type, year) => {
    setIsModalOpen(true);
    setSelectedCourseId(id);
    setSelectedTerms(alreadySelectedTerms);
    localStorage.setItem("selected_courseType_id", course_type);
    if (allTerms.length === 0) {
      const terms = await get_terms_by_year(year);
      setAllTerms(terms?.data);
    }
  };

  return (
    <>
      <div className="right-nav" id="dash-event">
        {showResults ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <h4 className="modal-title">Course List</h4>
              </div>
              <div className="col-md-8">
                {permissions?.add === 1 ? (
                  <Link
                    className="ui button float-end"
                    to="/admin/course-managment/course/add"
                  >
                    Add Course
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
            <CustomTableWithChild
              columns={columns2}
              data={Course}
              childColumns={childColumns}
            />
          </>
        ) : (
          <DataLoader />
        )}
      </div>
      <AddTerm
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        allTerms={allTerms}
        selectedCourseId={selectedCourseId}
        selectedTerms={selectedTerms}
      />
      <DuplicateCourse
        setIsModalOpen={setOpenDublicateModal}
        isModalOpen={openDublicateModal}
        ids={dublicateData}
      />
    </>
  );
};
export default AllCourses;
