import React, { useState, useEffect, useDebugValue } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { studentList } from "../../../api/Class";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { handleNavigation } from "../../../../Utils/Utils";
import {
  enrolled_course_status_save_branch,
  get_course_term_data,
  get_eligible_courses,
  get_enrolled_course_status,
  get_notAssinge_courses,
  update_enrolled_course_date,
  add_course_term_data
} from "../../../api/branches/Branch";
import { student_enroll_list } from "../../../api/Student_Enroll";
import { Button } from "semantic-ui-react";
const AssigneStudent = ({
  type,
  previousData,
  student_id,
  combinePreviousData,
  edi_class_id,
}) => {
  const navigate = useNavigate();
  const [studentLists, setStudentList] = useState([]);
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);
  const [termData, setTermData] = useState([]);
  const [selectedTermsByCourse, setSelectedTermsByCourse] = useState({});
  const [termSelectedUnitsByCourse, setTermSelectedUnitsByCourse] = useState(
    {}
  );
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [eligibleCourses, setEligibleCourses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assigneCourse, setAssigneCourse] = useState(1);
  const branchid = localStorage.getItem("b_id");
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();
  const { grade_id, term_id, class_id, course_id } = useParams();

  useEffect(() => {
    if (type === "edit" && previousData) {
      setTermSelectedUnitsByCourse(previousData);
    }
  }, [previousData, type]);
  useEffect(() => {
    async function get_all_data() {
      setStudentList(await studentList(grade_id, term_id));
      const result2 =
        type === "edit"
          ? await get_notAssinge_courses(student_id)
          : await get_eligible_courses(grade_id, term_id);
      setEligibleCourses(result2?.data || []);
    }
    get_all_data();
    // get_enroll_data();
  }, [course_id]);
  const handleStudentChange = (selectedSubOptions) => {
    setSelectedSubOptions(selectedSubOptions);
  };
  // enrolment status
  async function get_enroll_data() {
    if (course_id) {
      const result = await get_course_term_data(course_id);
      const course_selected_data = result?.data || [];
      const termsByCourseId = {};
      course_selected_data.forEach((course) => {
        const courseId = course.course_id;
        if (!termsByCourseId[courseId]) {
          termsByCourseId[courseId] = {
            courseName: course.course_name,
            courseTypeName: course.course_type_name,
            course_type_id: course.course_type_id,
            courseTypeName: course.course_type_name,
            terms: [],
          };
        }
        course.terms.forEach((term) => {
          termsByCourseId[courseId].terms.push({
            termName: term.term_name,
            term_id: term.term_id,
            subjects: term.subjects,
          });
        });
      });
      setTermData(termsByCourseId);
      const preSelectedTermsByCourse = {};
      const preSelectedUnitsByCourse = {};
      Object.keys(termsByCourseId).forEach((courseId) => {
        const courseTerms = termsByCourseId[courseId].terms;
        preSelectedTermsByCourse[courseId] = [];
        preSelectedUnitsByCourse[courseId] = [];
        courseTerms.forEach((term, termIndex) => {
          let hasUnitsWithStatus1 = false;
          term.subjects.forEach((subject) => {
            subject.units.forEach((unit) => {
              if (unit.status === 1) {
                hasUnitsWithStatus1 = true;
              }
            });
          });
          if (hasUnitsWithStatus1) {
            preSelectedTermsByCourse[courseId].push(term.termName);
          }
          term.subjects.forEach((subject, subjectIndex) => {
            preSelectedUnitsByCourse[courseId][termIndex] = [];
            subject.units.forEach((unit) => {
              if (unit.status === 1) {
                preSelectedUnitsByCourse[courseId][termIndex].push(
                  unit.unit_id
                );
              }
            });
          });
        });
      });
      setSelectedTermsByCourse(preSelectedTermsByCourse);
      setTermSelectedUnitsByCourse(preSelectedUnitsByCourse);
    }
  }
  const handleLectureInputChange = (e, courseId, termIndex, subjectIndex, unitId) => {
    setTermSelectedUnitsByCourse((prevSelectedUnits) => {
      const updatedUnits = { ...prevSelectedUnits };
      // Ensure the course, term, and subject exist in the state
      if (!updatedUnits[courseId]) updatedUnits[courseId] = {};
      if (!updatedUnits[courseId][termIndex])
        updatedUnits[courseId][termIndex] = {};
      if (!updatedUnits[courseId][termIndex][subjectIndex]) {
        updatedUnits[courseId][termIndex][subjectIndex] = [];
      }
      // Toggle unit selection
      const isUnitSelected =
        updatedUnits[courseId][termIndex][subjectIndex].includes(unitId);
      if (isUnitSelected) {
        updatedUnits[courseId][termIndex][subjectIndex] = updatedUnits[
          courseId
        ][termIndex][subjectIndex].filter((id) => id !== unitId);
      } else {
        updatedUnits[courseId][termIndex][subjectIndex].push(unitId);
      }
      // Clean up empty objects and arrays
      if (updatedUnits[courseId][termIndex][subjectIndex].length === 0) {
        delete updatedUnits[courseId][termIndex][subjectIndex];
      }
      if (Object.keys(updatedUnits[courseId][termIndex]).length === 0) {
        delete updatedUnits[courseId][termIndex];
      }
      if (Object.keys(updatedUnits[courseId]).length === 0) {
        delete updatedUnits[courseId];
      }

      return updatedUnits;
    });
  };
  const handleTermCheckboxChange = (courseId, termIndex) => {
    setTermSelectedUnitsByCourse((prevSelectedUnits) => {
      const updatedUnits = { ...prevSelectedUnits };
      const allUnitsInTerm = termData[courseId].terms[
        termIndex
      ].subjects.flatMap((subject) =>
        subject.units.map((unit) => unit.unit_id)
      );

      if (!updatedUnits[courseId]) updatedUnits[courseId] = {};
      if (!updatedUnits[courseId][termIndex])
        updatedUnits[courseId][termIndex] = {};
      const allUnitsSelected = termData[courseId].terms[
        termIndex
      ].subjects.every((subject, subjectIndex) =>
        allUnitsInTerm.every((unitId) =>
          updatedUnits[courseId][termIndex]?.[subjectIndex]?.includes(unitId)
        )
      );

      if (allUnitsSelected) {
        delete updatedUnits[courseId][termIndex];
      } else {
        termData[courseId].terms[termIndex].subjects.forEach(
          (subject, subjectIndex) => {
            updatedUnits[courseId][termIndex][subjectIndex] = subject.units.map(
              (unit) => unit.unit_id
            );
          }
        );
      }
      if (Object.keys(updatedUnits[courseId][termIndex] || {}).length === 0) {
        delete updatedUnits[courseId][termIndex];
      }
      if (Object.keys(updatedUnits[courseId] || {}).length === 0) {
        delete updatedUnits[courseId];
      }

      return updatedUnits;
    });
  };

  const onSubmit = async () => {
    const submissionData = [];
    Object.keys(termData).forEach((courseId) => {
      const course = termData[courseId]; 
      const courseTypeName = course.courseTypeName; 
      const course_type_id = course.course_type_id; 
      const courseName = course.courseName;
      const courseTerms = course?.terms || [];
      courseTerms.forEach((term, termIndex) => {
        const termUnits = termSelectedUnitsByCourse[courseId]?.[termIndex] || {};  
        term.subjects.forEach((subject, subjectIndex) => {
          const allUnits = subject.units || []; 
          allUnits.forEach((unit) => {
            const isUnitSelected =
              termUnits[subjectIndex]?.includes(unit.unit_id) || false; 
            submissionData.push({
              course_id: courseId,
              term_name: term.termName,
              term_id: term.term_id,
              subject_id: subject.subject_id,
              unit_id: unit.unit_id,
              course_type_name: courseTypeName,
              course_type_id: course_type_id,
              course_name: courseName, 
              status: isUnitSelected ? 1 : 0, 
            });
          });
        });
      });
    });

    const combinedData =
      type === "edit"
        ? combinePreviousData(submissionData, previousData)
        : submissionData;

    const payload = {
      student_id:
        type === "edit"
          ? student_id
          : selectedSubOptions?.map((ele) => ele?.value),
      courses: combinedData,
      // newcourse: selectedCourse,
    };
    console.log(payload);

    // return
    const result = await add_course_term_data(payload, class_id, branchid);

    if (result?.status === true) {
      navigate("/branch/class-management/all");
    } else {
      console.error("Save Failed", result);
    }
  };
  // term data
  useEffect(() => {
    if (selectedSubOptions?.length > 0) {
      get_enroll_data()
    }
  }, [selectedSubOptions])
  // const assignCourse = async () => {
  //   if (isSubmitting) return;
  //   setIsSubmitting(true);
  //   const payload = {
  //     student_id:
  //       type === "edit"
  //         ? [student_id]
  //         : selectedSubOptions?.map((ele) => ele?.value),
  //     courses: [],
  //     newcourse: course_id,
  //   };

  //   const result = await enrolled_course_status_save_branch(
  //     class_id || edi_class_id,
  //     branchid,
  //     payload
  //   );
  //   if (result.status === true) {
  //     setAssigneCourse(2);
  //     get_enroll_data();
  //   }
  //   setIsSubmitting(false);
  // };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <section className="courses-sec">
          <div className="modal-header">
            <h4 className="modal-title">Assign Students & Terms</h4>
            <Link
              className="edit-btn"
              to={`/branch/class-management/all`}
              onClick={(e) => {
                handleNavigation(e, isDirty);
              }}
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </Link>
          </div>
          {type !== "edit" && (
            <div className="modal-body">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Student List *</label>
                  <Controller
                    name="student"
                    control={control}
                    defaultValue={[]}
                    rules={{ required: "This is required" }}
                    render={({ field }) => (
                      <Select
                        options={studentLists}
                        placeholder="Choose Students"
                        isMulti
                        {...field}
                        value={selectedSubOptions}
                        onChange={(otopn) => {
                          handleStudentChange(otopn);
                          field.onChange(otopn);
                        }}
                      />
                    )}
                  />
                  {errors.subject && (
                    <span className="validation-error">
                      {errors.subject.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
        <div>
          <section className="courses-sec">
            <div className="modal-header">
              {/* <h4 className="modal-title">Enrolment Status</h4> */}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="courses-content">
                      {/* <ul className="nav nav-tabs" id="myTab" role="tablist"> */}
                      {/* {Object.keys(termData).map((courseId, index) => (
                          <li
                            key={courseId}
                            className="nav-item"
                            role="presentation"
                          >
                            <button
                              className={`nav-link ${
                                index === 0 ? "active" : ""
                              }`}
                              id={`course-tab-${courseId}`}
                              data-bs-toggle="tab"
                              data-bs-target={`#course-${courseId}`}
                              type="button"
                              role="tab"
                              aria-controls={`course-${courseId}`}
                              aria-selected={index === 0}
                            >
                              Course ID: {courseId}
                            </button>
                          </li>
                        ))} */}
                      {/* <li className="nav-item dropdown dropdown_right">
                          <label>Select course *</label>
                          <select
                            className="form-select form-control"
                            onChange={(e) => setSelectedCourse(e.target.value)}
                          >
                            <option value=""> + Add course</option>
                            {eligibleCourses &&
                              eligibleCourses?.map((elem) => {
                                return (
                                  <option value={elem?.id}>{elem?.name}</option>
                                );
                              })}
                          </select>
                        </li>
                      </ul> */}
                      <div className="tab-content" id="myTabContent">
                        {Object.keys(termData).map((courseId) => (
                          <div
                            key={courseId}
                            className={`tab-pane fade ${courseId === Object.keys(termData)[0]
                              ? "show active"
                              : ""
                              }`}
                            id={`course-${courseId}`}
                            role="tabpanel"
                            aria-labelledby={`course-tab-${courseId}`}
                          >
                            <h4>Course Name: {termData[courseId].courseName}</h4>
                            <h4>
                              Course Type: {termData[courseId].courseTypeName}
                            </h4>
                            <div className="accordion" id="accordionExample">
                              {termData[courseId].terms.map(
                                (term, termIndex) => {
                                  const isTermSelected = termData[
                                    courseId
                                  ]?.terms[termIndex]?.subjects.every(
                                    (subject, subjectIndex) =>
                                      subject.units.every((unit) =>
                                        termSelectedUnitsByCourse[courseId]?.[
                                          termIndex
                                        ]?.[subjectIndex]?.includes(
                                          unit.unit_id
                                        )
                                      )
                                  );
                                  return (
                                    <div
                                      key={termIndex}
                                      className="accordion-item"
                                    >
                                      <div
                                        key={termIndex}
                                        className="terms-sec"
                                      >
                                        <h2
                                          className="accordion-header checkboxheading"
                                          id={`heading-${termIndex}`}
                                        >
                                          <input
                                            className="form-check-input btncheckbox"
                                            type="checkbox"
                                            id={`term-checkbox-${termIndex}`}
                                            name="term"
                                            checked={isTermSelected}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={() =>
                                              handleTermCheckboxChange(
                                                courseId,
                                                termIndex
                                              )
                                            }
                                          />
                                          <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${termIndex}`}
                                            aria-expanded={
                                              termIndex === 0 ? true : false
                                            }
                                            aria-controls={`collapse-${termIndex}`}
                                          >
                                            <div className="form-check">
                                              <label
                                                className="form-check-label"
                                                htmlFor={`term-checkbox-${termIndex}`}
                                              >
                                                <b>Term: {term.termName}</b>
                                              </label>
                                            </div>
                                          </button>
                                        </h2>
                                        <div
                                          id={`collapse-${termIndex}`}
                                          className={`accordion-collapse collapse ${termIndex === 0 ? "show" : ""
                                            }`}
                                          aria-labelledby={`heading-${termIndex}`}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Units</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {term.subjects.map(
                                                (subjectObj, subjectIndex) => (
                                                  <tr key={subjectIndex}>
                                                    <td>
                                                      {subjectObj.subject_name}
                                                    </td>
                                                    <td>
                                                      {subjectObj.units.map(
                                                        (unit, unitIndex) => (
                                                          <div
                                                            key={unitIndex}
                                                            className="form-check"
                                                          >
                                                            <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              checked={
                                                                termSelectedUnitsByCourse[
                                                                  courseId
                                                                ]?.[
                                                                  termIndex
                                                                ]?.[
                                                                  subjectIndex
                                                                ]?.includes(
                                                                  unit.unit_id
                                                                ) || false
                                                              }
                                                              name="units"
                                                              onChange={(e) =>
                                                                handleLectureInputChange(
                                                                  e,
                                                                  courseId,
                                                                  termIndex,
                                                                  subjectIndex,
                                                                  unit.unit_id
                                                                )
                                                              }
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor={`checkbox-${unit.unit_id}`}
                                                            >
                                                              {unit.unit_name}{" "}
                                                              {unit.unit_id}
                                                            </label>
                                                          </div>
                                                        )
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {selectedSubOptions?.length > 0 &&
                <Button
                  type="submit"
                // disabled={subject?.length > 0 ? false : true}
                >
                  Save
                </Button>
              }
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AssigneStudent;
