import { toast } from "react-toastify";
import { HTTPURL } from "../../../constants/Matcher";

// add branch
export async function branch_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all branches
export async function branch_all() {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branches-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch edit 
export async function branch_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch update 
export async function branch_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch delete
export async function branch_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-distroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// add branch question time
export async function branch_add_question_time(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-add-question-time', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// edit branch question time
export async function branch_edit_question_time(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/edit-date-time?q_id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// update branch question time
export async function branch_question_time_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/branches/update-date-time?q_id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function enrolled_course_status_save_branch(class_id, branch_id, data) {
    console.log('class_id,branch_id,data', class_id, branch_id, data);
    // return
    try {
        const response = await fetch(HTTPURL + `api/branches/enrolled-course-status-save-branch?class_id=${class_id}&branch_id=${branch_id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



export async function enrolled_course_status_save(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/enrolled-course-status-save', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_enrolled_course_status(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/branches/get-enrolled-course-status-branch?course_id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_eligible_courses(grade_id, term_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/enrollment-eligible-courses-branch?grade_id=${grade_id}&term_id=${term_id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




export async function edit_assigne_student_data(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/get-enrolled-course-status?id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            // body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_notAssinge_courses(student_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/enrollment-eligible-courses?id=${student_id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function update_enrolled_course_date(data) {
    try {
        const response = await fetch(HTTPURL + `api/branches/enrolled-course-status-save`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_course_term_data(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/course-term-data?course_id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function add_course_term_data(data, class_id, branch_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/add-course-term-data?branch_id=${branch_id}&class_id=${class_id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}