import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import {
  get_course_term_data,
  course_lecthure_details_destroy,
  course_homework_details_destroy,
} from "../../apis/Course";
import { Link } from "react-router-dom";
const NestedExpandedComponent = ({ data }) => {
  const [courseTermData, setCourseTermData] = useState([]);
  const fetchData = async () => {
    const res = await get_course_term_data(data?.course_id, data?.id);
    setCourseTermData(res?.data);
  };
  useEffect(() => {
    fetchData();
  }, [data]);
  let globalIndex = 0;
  const destroyLecture = async (course_id, term_id, subject_id, unit_id) => {
    const result = window.confirm("Are you sure you want to delete this?");
    if (result) {
      await course_lecthure_details_destroy(
        course_id,
        term_id,
        subject_id,
        unit_id
      );
    }
    fetchData();
  };
  const destroyHowework = async (
    course_id,
    term_id,
    subject_id,
    unit_id,
    skill
  ) => {
    const result = window.confirm("Are you sure you want to delete this?");
    if (result) {
      await course_homework_details_destroy(
        course_id,
        term_id,
        subject_id,
        unit_id,
        skill
      );
    }
    fetchData();
  };

  return (
    <div>
      <br />
      <h5>Lectures </h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>No</th>
            <th>Subject</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Action</th>
            <th>Play</th>
          </tr>
        </thead>
        <tbody>
          {courseTermData &&
            courseTermData?.lectures?.map((elem, i) => {
              // console.log('lecthure Data',elem);

              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{elem?.subject ? elem?.subject : "-"}</td>
                  <td>{elem?.name ? elem?.name : "-"}</td>
                  <td>{elem?.unit_id ? elem?.unit_id : "-"}</td>
                  <td>
                    {" "}
                    <Link
                      className="edit-btn"
                      to={`/admin/course-managment/course/edit/${elem?.course_id}/${elem?.term_id}/${elem?.course_type_id}/${elem?.grade_id}/${elem?.year}`}
                      onClick={() => {
                        localStorage.setItem(
                          "courseSubjectID",
                          parseInt(elem?.subject_id)
                        );
                        localStorage.setItem("CourseEditType", "term");
                      }}
                      state={{
                        currentStepActive: 2,
                        isNotVisibleFirstStep: true,
                      }}
                    >
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </Link>{" "}
                    <button
                      className="dlt-btn"
                      onClick={() =>
                        destroyLecture(
                          elem?.course_id,
                          elem?.term_id,
                          elem?.subject_id,
                          elem?.unit_id
                        )
                      }
                    >
                      {" "}
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td>
                    {" "}
                    {elem?.units
                      ?.filter((item) => item.abbreviation)
                      .map((item, index) => (
                        <a
                          key={index}
                          href={item.file_path || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: 5 }}
                        >
                          {item.abbreviation}
                        </a>
                      ))
                      .reduce(
                        (prev, curr) => (prev ? [prev, " | ", curr] : [curr]),
                        null
                      )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <h5>Home Work </h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>No</th>
            <th>Subject</th>
            <th>Skill</th>
            <th>Unit</th>
            <th>TSTM GUID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {courseTermData &&
            courseTermData?.homeworks?.map((elem, i) => {
              if (!elem?.units || elem?.units.length === 0) {
                return (
                  <tr key={++globalIndex}>
                    <td>{globalIndex}</td>
                    <td>{elem?.subject ? elem?.subject : "-"}</td>
                    <td>-</td>
                    <td>{elem?.unit_id ? elem?.unit_id : "-"}</td>
                    <td>-</td>
                    <td>
                      {" "}
                      <Link
                        className="edit-btn"
                        to={`/admin/course-managment/course/edit/${elem?.course_id}/${elem?.term_id}/${elem?.course_type_id}/${elem?.grade_id}`}
                        onClick={() => {
                          localStorage.setItem(
                            "courseSubjectID",
                            parseInt(elem?.subject_id)
                          );
                          localStorage.setItem("CourseEditType", "term");
                        }}
                        state={{
                          currentStepActive: 2,
                          learningStepActive: 3,
                          isNotVisibleFirstStep: true,
                        }}
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </Link>{" "}
                      <button
                        className="dlt-btn"
                        onClick={() =>
                          destroyHowework(
                            elem?.course_id,
                            elem?.term_id,
                            elem?.subject_id,
                            elem?.unit_id
                          )
                        }
                      >
                        {" "}
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                );
              } else {
                return elem?.units.map((item) => (
                  <tr key={`${globalIndex}`}>
                    <td>{++globalIndex}</td>
                    <td>{elem?.subject ? elem?.subject : "-"}</td>
                    <td>{item?.abbreviation ? item?.abbreviation : "-"}</td>
                    <td>{elem?.unit_id ? elem?.unit_id : "-"}</td>
                    <td>{item?.tstm_guid ? item?.tstm_guid : "-"}</td>
                    <td>
                      {" "}
                      <Link
                        className="edit-btn"
                        to={`/admin/course-managment/course/edit/${elem?.course_id}/${elem?.term_id}/${elem?.course_type_id}/${elem?.grade_id}/${elem?.year}`}
                        onClick={() => {
                          localStorage.setItem(
                            "courseSubjectID",
                            parseInt(elem?.subject_id)
                          );
                          localStorage.setItem("CourseEditType", "term");
                        }}
                        state={{
                          currentStepActive: 2,
                          learningStepActive: 3,
                          isNotVisibleFirstStep: true,
                        }}
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </Link>{" "}
                      <button
                        className="dlt-btn"
                        onClick={() =>
                          destroyHowework(
                            elem?.course_id,
                            elem?.term_id,
                            elem?.subject_id,
                            elem?.unit_id,
                            item?.skill
                          )
                        }
                      >
                        {" "}
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ));
              }
            })}
        </tbody>
      </table>
    </div>
  );
};

const CustomTableWithChild = ({ columns, data, childColumns, type }) => {
  const [searchData, setSearchData] = useState("");
  const [filterData, setFilterData] = useState([]);

  const onSearch = (value, sortByKey) => {
    setSearchData(value);
    const searchRes = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    const sortedData = searchRes.sort((a, b) => {
      const aValue = a[sortByKey]?.toLowerCase();
      const bValue = b[sortByKey]?.toLowerCase();
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });
    setFilterData(sortedData);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="p-4">
        {type === "class" ? (
          <DataTable
            noHeader
            columns={childColumns}
            data={data.terms}
            defaultSortField="id"
          />
        ) : (
          <DataTable
            noHeader
            columns={childColumns}
            data={data.terms}
            defaultSortField="id"
            expandableRows
            expandableRowsComponent={({ data }) => (
              <NestedExpandedComponent data={data} />
            )}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="searc-bar">
        <input
          type="search"
          className="form-control float-end"
          placeholder="Search here..."
          onChange={(e) => onSearch(e.target.value, "name")}
        />
      </div>
      <DataTable
        noHeader
        columns={columns}
        data={searchData ? filterData : data}
        defaultSortField="id"
        defaultSortAsc={true}
        pagination
        paginationPerPage={10}
        sortMode="multiple"
        highlightOnHover
        expandableRows
        expandableRowsComponent={ExpandedComponent}
      />
    </>
  );
};

export default CustomTableWithChild;
