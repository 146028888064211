import React, { useEffect, useState } from "react";

const AddLectures = ({ setSub, movieTypes, sub,subjectId }) => {
  const [forms, setForms] = useState([]); // Use the `sub` from the parent if available.
  useEffect(() => {
    setForms(sub);
  }, [sub]);
  console.log("#%#%$",movieTypes);
  
  useEffect(() => {
    if (forms.length === 0) {
      const totalUnits = parseInt(
        localStorage.getItem("totalUnits") || "1",
        10
      );
      const initialForms = Array.from({ length: totalUnits }, (_, index) => ({
        unit_id: index + 1,
        lectures: movieTypes?.map((movieType) => ({
          movie_type: "",
          title: "",
          file_path: "",
          jwp_media: "",
        })),
      }));
      setForms(initialForms);
      setSub(initialForms); // Update the parent state
    }
  }, [movieTypes,subjectId]);

  const handleLectureInputChange = (e, unitIndex, movieIndex) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[unitIndex].lectures[movieIndex] = {
      ...updatedForms[unitIndex].lectures[movieIndex],
      [name]: value,
    };
    setForms(updatedForms);
    setSub(updatedForms); // Updates the parent state
  };
  return (
    <div className="accordion" id="accordionExample">
      {forms.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className={`accordion-button ${i === 0 ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? "show" : ""}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                <input type="hidden" name="unit_id" value={form.unit_id} />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Movie Type</th>
                      <th>Title</th>
                      <th>File Path</th>
                      <th>JWP Media</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.lectures.map((lecture, movieIndex) => (
                      <tr key={`${form.unit_id}-${movieIndex}`}>
                        <td className="text-center">{movieIndex + 1}</td>
                        <td>
                          <select
                            className="form-select"
                            name="movie_type"
                            value={lecture.movie_type}
                            onChange={(e) =>
                              handleLectureInputChange(e, i, movieIndex)
                            }
                          >
                            <option value="">Select Type</option>
                            {movieTypes.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.file_type}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={lecture.title}
                            onChange={(e) =>
                              handleLectureInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="file_path"
                            value={lecture.file_path}
                            onChange={(e) =>
                              handleLectureInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="jwp_media"
                            value={lecture.jwp_media}
                            onChange={(e) =>
                              handleLectureInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddLectures;
