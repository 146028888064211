import React, { useEffect, useState } from "react";

const AddHomeWork = ({ setHomeWorkData, skillData, homeWorkData }) => {
  const [forms, setForms] = useState([]);

  console.log("skillData", skillData, "forms", forms, homeWorkData);

  // Update forms when homeWorkData prop changes.
  useEffect(() => {
    if (homeWorkData && homeWorkData.length > 0) {
      setForms(homeWorkData);
    }
  }, [homeWorkData]);
  console.log(skillData,"****skilldata");
  

  // Initialize forms when skillData is available and homeWorkData is empty.
  useEffect(() => {
    if (homeWorkData.length === 0) {
      const totalUnits = parseInt(
        localStorage.getItem("totalUnits") || "1",
        10
      );
      console.log("homeworkdata",homeWorkData);
      console.log("skilldata",skillData);
      
      
      
      const initialForms = Array.from({ length: totalUnits }, (_, index) => ({
        unit_id: index + 1,
        homeWorkData: skillData.map((skill,skillindex) => ({
          skill: "",
          title: "",
          tstm_guid: "",
        })),
      }));
      setForms(initialForms);
      setHomeWorkData(initialForms);
    }
  }, [skillData]);

  // Handle changes to homeWorkData input fields.
  const handlehomeWorkInputChange = (e, unitIndex, homeWorkIndex) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[unitIndex].homeWorkData[homeWorkIndex][name] = value;
    setForms(updatedForms);
    setHomeWorkData(updatedForms);
  };

  return (
    <div className="accordion" id="accordionExample">
      {forms.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className={`accordion-button ${i === 0 ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? "show" : ""}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                <input type="hidden" name="unit_id" value={form.unit_id} />
                <table className="table table-bordered edit_course_select">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Skill Type</th>
                      <th>Title</th>
                      <th>TSTM GUID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.homeWorkData.map((homeWorkData, movieIndex) => (
                      <tr key={`${form.unit_id}-${movieIndex}`}>
                        <td className="text-center">{movieIndex + 1}</td>
                        <td>
                          <select
                            className="form-select w-100"
                            name="skill"
                            value={homeWorkData.skill}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          >
                            <option value="">Select Type</option>
                            {skillData.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.skill}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={homeWorkData.title}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="tstm_guid"
                            value={homeWorkData.tstm_guid}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddHomeWork;
