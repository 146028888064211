import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import {
  sub_users_list,
  sub_users_update,
  destroy_user,
} from "../../../../apis/generalSetting/SubUsers";
import CustomTable from "../../../pages/CustomTable";
import { getUserPermission } from "../../../../Utils/Utils";

const SubUser_List = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const permissions = getUserPermission("Users");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    // {
    //   name: "Mobile No",
    //   selector: "number",
    //   sortable: true,
    // },
    {
      name: "Role",
      selector: "roles",
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: "Branch",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await sub_users_list();
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          first_name,
          last_name,
          email,
          roles,
          payment,
          branch,
          c_name,
          delete_status,
          status,
          branch_name,
        } = elem;
        if (result?.data.length > 0 && selectedStatus === null) {
          setSelectedStatus(delete_status);
        }
        return {
          no: `#${index + 1}`,
          name: `${first_name} ${last_name ? last_name : ""}`,
          email: email,
          roles: roles ? roles : "-",
          Branch: branch_name ? branch_name : "-",
          status: ` ${status == 1 ? "Active" : "Inactive"}`,
          permission: `payemnt:${payment == 1 ? "Yes" : "NO"},
                                 branch:${branch == 1 ? "Yes" : "NO"},
                                 Class:${c_name == 1 ? "Yes" : "NO"}`,
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/admin/administrator-setting/sub-users/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() =>
                    destroy(id, delete_status === 1 ? 0 : 1, first_name)
                  }
                >
                  {" "}
                  {delete_status === 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, first_name) => {
      const data = {
        delete_status: status,
        deleted_by: UserID,
        title: first_name,
      };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active this user ?"
            : "Are you sure you want to inactive  this user ? "
        }`
      );
      if (result) {
        await destroy_user(data, id);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Users List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/administrator-setting/sub-users/add"
                >
                  Add User
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default SubUser_List;
