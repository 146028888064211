import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { grade_all } from "../../../apis/Grade";
import {
  movie_type_by_subjectId,
  skill_by_subjectId,
  test_type_list,
} from "../../../apis/Common.jsx";
import { get_all_year_data } from "../../../apis/generalSetting/WebsiteEnv.jsx";
import { get_category } from "../../../apis/category";
import { subject_by_coursetype } from "../../../apis/ClassManagement/Subjects.jsx";
import {
  course_edit,
  course_evaluation,
  course_update,
  update_course_consulting,
  update_course_etutoring,
  update_course_evaluation,
  update_course_homework,
  update_course_lecture,
  update_course_self_study,
} from "../../../apis/Course";
import EditAssessment from "./Assessment/Edit.jsx";
import EditLecture from "./Subject/Edit.jsx";
import EditHomework from "./HomeWork/Edit.jsx";
import EditSelfStudy from "./SelfStudy/Edit.jsx";
import EditConsulting from "./Consulting/Edit.jsx";
import EditEvaluation from "./Evaluation/Edit.jsx";
import EditeTutoring from "./E_Tutoring/Edit.jsx";
import axios from "axios";
import { HTTPURL } from "../../../constants/Matcher.jsx";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../Utils/Utils.js";
const EditCourse = () => {
  const [coursesubjects, setCoursesubjects] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [courseType, setCourseType] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [learnigStep, setLearningStep] = useState(0);
  const [homeWorkData, setHomeWorkData] = useState([]);
  const [eTuoringData, setEtutoringData] = useState([]);
  const [ConsultingData, setConsultingData] = useState([]);
  const [evaluationData, setEvaluationData] = useState([]);
  const [date, setDate] = useState("");
  const [homeWorkDate, setHomeWorkDate] = useState("");
  const [singleTypeFormdata, setSingleTypeFormdata] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [testTypeData, setTestTypeData] = useState([]);
  const [movieTypeData, setMovieTypeData] = useState([]);
  const [lecture, setLecture] = useState([]);
  const [homeWork, setHomeWork] = useState([]);
  const [selfStudy, setSelfStudy] = useState([]);
  const [eTutroing, setEtutoring] = useState([]);
  const [consulting, setConsulting] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [CallEvaluationList, setCallEvaluationList] = useState(false);
  //state for check evaluation correct date
  const [CheckEvaluationValidation,setCheckEvaluationValidation]=useState(true);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { course_id, id, course_type_id, grade_id, year } = useParams();
  const Subject_id = localStorage.getItem("courseSubjectID");
  const [selectedSubjectId, setSelectedSubjectId] = useState(Subject_id);
  const fetchLectureData = async () => {
    if (id && course_id) {
      try {
        const response = await axios.get(
          `${HTTPURL}api/edit-course-lecture?course_id=${course_id}&type=${
            learnigStep ? learnigStep : 1
          }&term_id=${id}`
        );
        setSingleTypeFormdata(response?.data?.data);
        if (learnigStep === 1 && response?.data?.data?.data[0]?.start_date) {
          // setValue("start_date_lecture", response?.data?.data?.data[0]?.start_date);
        }
      } catch (err) {}
    }
  };
  useEffect(() => {
    fetchLectureData();
  }, [learnigStep, id, selectedSubjectId]);
  useEffect(() => {
    if (!getValues("start_date_lecture")) {
      setValue("start_date_lecture", singleTypeFormdata?.data[0]?.start_date);
    }
  }, [singleTypeFormdata]);
  useEffect(() => {
    const currentStepActive = location.state?.currentStepActive;
    const learningStepActive = location.state?.learningStepActive;
    if (currentStepActive) {
      setCurrentStep(currentStepActive);
      setLearningStep(learningStepActive ? learningStepActive : 1);
    }
  }, []);
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const form = new FormData();
    form.append("grade_id", getValues("grade_id"));
    form.append("course_type_id", getValues("course_type_id"));
    form.append("name", getValues("name"));
    form.append("status", getValues("status"));
    form.append("price", getValues("price"));
    form.append("year", getValues("year"));
    if (currentStep === 1) {
      const result = await course_update(id, form);
      if (result.status) {
        const course_id = result.data;
        localStorage.setItem("course_id", course_id);
        navigate("/admin/course-managment/course/all");
      }
    } else {
      if (!location?.state?.isNotVisibleSecondStep) {
        setCurrentStep(2);
        setLearningStep(1);
      }
    }
    setIsSubmitting(false);
  };

  const preparePayload = () => {
    let payload = { data: [] };
    if (learnigStep === 1) {
      const start_date = getValues("start_date_lecture");
      payload = {
        data: lecture.map((unit) => ({
          unit_id: unit.unit_id || "",
          start_date,
          subject_id: selectedSubjectId,
          units_json_data: unit?.lectures?.map((lecture) => ({
            movie_type: lecture?.movie_type || "",
            title: lecture?.title || "",
            file_path: lecture?.file_path || "",
            jwp_media: lecture?.jwp_media || "",
          })),
        })),
      };
    } else if (learnigStep === 2) {
      const start_date = getValues("start_date_lecture");
      payload = {
        data: selfStudy.map((unit) => ({
          unit_id: unit.unit_id || "",
          start_date,
          subject_id: selectedSubjectId,
          units_json_data: unit?.selfDir?.map((selfDir) => ({
            movie_type: selfDir?.movie_type || "",
            title: selfDir?.title || "",
            file_path: selfDir?.file_path || "",
            jwp_media: selfDir?.jwp_media || "",
          })),
        })),
      };
    } else if (learnigStep === 3) {
      const start_date = getValues("start_date_lecture");
      payload = {
        data: homeWork.map((unit) => ({
          unit_id: unit.unit_id || "",
          start_date,
          subject_id: selectedSubjectId,
          units_json_data: unit.homeWork.map((homeWorkItem) => ({
            skill: homeWorkItem.skill || "",
            title: homeWorkItem.title || "",
            tstm_guid: homeWorkItem.tstm_guid || "",
          })),
        })),
      };
    } else if (learnigStep === 6) {
      payload = {
        data: evaluation,
      };
    }
    return payload;
  };
  const onSubmitSteps = async () => {
    setCallEvaluationList(false);
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const apiMappings = {
        1: update_course_lecture,
        2: update_course_self_study,
        3: update_course_homework,
        4: update_course_etutoring,
        5: update_course_consulting,
        6: course_evaluation,
      };
      const apiFunction = apiMappings[learnigStep];
      if (!apiFunction) {
        console.error("No API function found for this step.");
        return;
      }
      const formData = preparePayload(currentStep);
      const result = await apiFunction(course_id, id, formData);
      if (result.status) {
        if (learnigStep === 6) {
          setCallEvaluationList(true);
        }
        console.log(`data submitted successfully!`);
      } else {
        console.error("Submission failed:", result);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setCallEvaluationList(false);
    }
    setIsSubmitting(false);
  };
  const type = localStorage.getItem("CourseEditType");
  //course get data api
  useEffect(() => {
    const fetchData = async () => {
      const gradeList = await grade_all();
      const courseType = await get_category();
      const yearData = await get_all_year_data();
      setGradeList(gradeList?.data);
      setCourseType(courseType?.data);
      setYearData(yearData?.data);
    };
    const get_data = async () => {
      const result = await course_edit(id);
      if (result?.data?.length > 0) {
        const {
          name,
          status,
          grade_id,
          course_type_id,
          image,
          totalUnits,
          price,
          term_id,
          year,
        } = result?.data[0];
        localStorage.setItem("totalUnits", totalUnits);
        setValue("name", name);
        setValue("status", status);
        setValue("price", price);
        setTimeout(() => {
          setValue("grade_id", grade_id.toString());
          setValue("course_type_id", course_type_id.toString());
          setValue("term_id", term_id?.toString());
          setValue("year", year);
        }, 2000);
      }
    };
    fetchData();
    get_data();
  }, []);
  useEffect(() => {
    if (course_type_id?.length > 0) {
      const getData = async () => {
        const result2 = await subject_by_coursetype(course_type_id);
        setCoursesubjects(result2?.data);
        if (result2?.data.length > 0 && type !== "term") {
          setSelectedSubjectId(result2?.data[0]?.value);
        }
      };
      getData();
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (learnigStep === 3) {
        const skillData = await skill_by_subjectId(selectedSubjectId);
        setSkillData(skillData?.data);
      } else if (learnigStep === 6) {
        const testTypeData = await test_type_list(year, grade_id, id);
        setTestTypeData(testTypeData?.data);
      } else {
        if (typeof selectedSubjectId === "number") {
          const movieTypeData = await movie_type_by_subjectId(
            selectedSubjectId
          );
          console.log(
            "movieTypeDatamovieTypeDatamovieTypeDatamovieTypeData",
            movieTypeData
          );
          setMovieTypeData(movieTypeData?.data);
        }
      }
    };
    fetchData();
  }, [selectedSubjectId, learnigStep]);
  useEffect(() => {
    if (type === "term") {
      setSelectedSubjectId(parseInt(Subject_id));
    }
  }, [type]);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Edit Course</h4>
          </div>
          <div className="col-md-6 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/course-managment/course/all`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {!location?.state?.isNotVisibleFirstStep ? (
            <li className="nav-item" role="presentation">
              <button
                onClick={() => setCurrentStep(1)}
                className={`nav-link ${currentStep === 1 ? "active " : ""}`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Basic Info
              </button>
            </li>
          ) : (
            ""
          )}
          {!location?.state?.isNotVisibleSecondStep ? (
            <li className="nav-item" role="presentation">
              <button
                onClick={() => (setCurrentStep(2), setLearningStep(1))}
                className={`nav-link ${currentStep === 2 ? "active " : ""}`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                style={{ cursor: "auto" }}
              >
                Steps of learning
              </button>
            </li>
          ) : (
            ""
          )}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {currentStep === 1 && (
            <form
              className="form-horizontal"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Grade *</label>
                      <select
                        className="form-select form-control"
                        {...register("grade_id", { required: true })}
                      >
                        <option value="">Choose Grade </option>
                        {gradelist?.length > 0 ? (
                          gradelist.map((elem) => {
                            return (
                              <option key={elem?.id} value={elem?.id}>
                                {elem.name === 0
                                  ? `Year ${elem.name} (kindy)`
                                  : `Year ${elem.name}`}
                              </option>
                            );
                          })
                        ) : (
                          <option>No result</option>
                        )}
                      </select>
                      {errors.grade_id &&
                        errors.grade_id.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Year *</label>
                      <select
                        className="form-select form-control"
                        {...register("year", { required: true })}
                      >
                        <option value="">Choose Year</option>
                        {yearData?.length > 0 ? (
                          yearData.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.current_year}
                              </option>
                            );
                          })
                        ) : (
                          <option>No result</option>
                        )}
                      </select>
                      {errors.year && errors.year.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Course Type *</label>
                      <select
                        className="form-select form-control"
                        {...register("course_type_id", { required: true })}
                      >
                        <option value="">Choose Course Type </option>
                        {courseType &&
                          courseType?.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.category_name}
                              </option>
                            );
                          })}
                      </select>
                      {errors.course_type_id &&
                        errors.course_type_id.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>course Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Course Name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Unit Price *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Course Price"
                        onKeyPress={(event) => {
                          if (!/[\d\.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("price", { required: true })}
                      />
                      {errors.price && errors.price.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>status</label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status", { required: true })}
                          id="inlineRadio1"
                          value="1"
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          Active
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status", { required: true })}
                          id="inlineRadio2"
                          value="0"
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Inactive
                        </label>
                      </div>
                      {errors.status && errors.status.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {currentStep === 1 && (
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn button"
                    disabled={isSubmitting}
                  >
                    {!location?.state?.isNotVisibleSecondStep
                      ? "Save & Next"
                      : "Save"}
                  </button>
                </div>
              )}
            </form>
          )}
          {currentStep === 2 && (
            <>
              <div
                className="tab-pane fade show active"
                id="pills-home1"
                role="tabpane"
                aria-labelledby="pills-home-tab1"
              >
                <div className="modal-body">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab1"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      {/* <button onClick={() => setLearningStep(1)} className={`nav-link ${learnigStep === 1 ? 'active ' : ''}`} id="pills-assesment-test-tab" data-bs-toggle="pill" data-bs-target="#pills-assesment-test" type="button" role="tab" aria-controls="pills-assesment-test" aria-selected="true">Assessment Test</button> */}
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          learnigStep === 1 ? "active " : ""
                        }`}
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Lectures
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          learnigStep === 2 ? "active " : ""
                        }`}
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Self Directed Study
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          learnigStep === 3 ? "active " : ""
                        }`}
                        type="button"
                        role="tab"
                        aria-controls="pills-homework"
                        aria-selected="false"
                        style={{ cursor: "auto" }}
                      >
                        Homework / Topic Test
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          learnigStep === 4 ? "active " : ""
                        }`}
                        type="button"
                        role="tab"
                        aria-controls="pills-e-tutoring"
                        aria-selected="false"
                        style={{ cursor: "auto" }}
                      >
                        E-Tutoring
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          learnigStep === 5 ? "active " : ""
                        }`}
                        type="button"
                        role="tab"
                        aria-controls="pills-consulting"
                        aria-selected="false"
                        style={{ cursor: "auto" }}
                      >
                        Consulting
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          learnigStep === 6 ? "active " : ""
                        }`}
                        type="button"
                        role="tab"
                        aria-controls="pills-evaluation"
                        aria-selected="false"
                        style={{ cursor: "auto" }}
                      >
                        Evaluation
                      </button>
                    </li>
                    <li>
                      <div className="modal-footer">
                        {learnigStep >= 2 && (
                          <button
                            className="btn"
                            onClick={() =>
                              setLearningStep(
                                learnigStep === 6
                                  ? learnigStep - 3
                                  : learnigStep - 1
                              )
                            }
                          >
                            Previous
                          </button>
                        )}
                        {learnigStep >= 0 && learnigStep < 6 && (
                          <Button
                            className="btn button"
                            onClick={() =>
                              setLearningStep(
                                learnigStep === 3
                                  ? learnigStep + 3
                                  : learnigStep + 1
                              )
                            }
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </li>
                  </ul>
                  <div className="tab-content1" id="pills-tabContent1">
                    {/* {learnigStep === 1 && (
                                                // <EditAssessment setInputList={setInputList} inputList={inputList} setAssessmentData={setAssessmentData} assessmentData={assessmentData} />
                                            )} */}
                    {learnigStep === 1 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 1 - Lectures</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              {/* <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects?.map((elem) => {
                                    debugger
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${
                                          selectedSubjectId === elem.value
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) => {
                                            setSelectedSubjectId(elem?.value);
                                          }}
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul> */}
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${
                                          selectedSubjectId === elem.value
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected={
                                            String(selectedSubjectId) ===
                                            String(elem.value)
                                              ? "true"
                                              : "false"
                                          }
                                          onClick={() =>
                                            setSelectedSubjectId(elem.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          {singleTypeFormdata &&
                          selectedSubjectId &&
                          singleTypeFormdata[
                            `subject_id_${selectedSubjectId}`
                          ] &&
                          selectedSubjectId &&
                          singleTypeFormdata[`subject_id_${selectedSubjectId}`]
                            .length > 0 ? (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Start Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="start_date"
                                  pattern="dd-mm-yyyy"
                                  max="9999-12-31"
                                  value={
                                    singleTypeFormdata[
                                      `subject_id_${selectedSubjectId}`
                                    ][0]?.start_date
                                  }
                                  onChange={(e) => setDate(e.target.value)}
                                  required
                                  disabled={
                                    singleTypeFormdata[
                                      `subject_id_${selectedSubjectId}`
                                    ][0]?.start_date
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Start Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="start_date"
                                  pattern="dd-mm-yyyy"
                                  max="9999-12-31"
                                  {...register("start_date_lecture", {
                                    required: "Start date is required!",
                                    validate: (value) => {
                                      const selectedDate = new Date(value);
                                      const todayDate = new Date();
                                      return (
                                        selectedDate > todayDate ||
                                        "Please select a future date."
                                      );
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          )}
                          <EditLecture
                            subjectIds={selectedSubjectId}
                            setLecture={setLecture}
                            selectedSubjectId={selectedSubjectId}
                            singleTypeFormdata={singleTypeFormdata}
                            movieTypes={movieTypeData}
                          />
                          <div className="modal-footer">
                            {learnigStep === 1 && (
                              <Button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </Button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 1 && (
                              <Button
                                className="btn button"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </Button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/admin/course-managment/course/all");
                                localStorage.removeItem("course_id");
                                localStorage.removeItem("term_id");
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 2 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Step 2 - Self Directed Study
                          </h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${
                                          selectedSubjectId === elem.value
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) => {
                                            setSelectedSubjectId(elem?.value);
                                          }}
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Start Date </label>
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                pattern="dd-mm-yyyy"
                                max="9999-12-31"
                                {...register("start_date_lecture", {
                                  required: "Start date is required!",
                                  validate: (value) => {
                                    const selectedDate = new Date(value);
                                    const todayDate = new Date();
                                    return (
                                      selectedDate > todayDate ||
                                      "Please select a future date."
                                    );
                                  },
                                })}
                              />
                            </div>
                          </div>
                          <EditSelfStudy
                            setSelfStudy={setSelfStudy}
                            selectedSubjectId={selectedSubjectId}
                            singleTypeFormdata={singleTypeFormdata}
                            movieTypes={movieTypeData}
                          />
                          <div className="modal-footer">
                            {learnigStep === 2 && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 2 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 2 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/admin/course-managment/course/all");
                                localStorage.removeItem("course_id");
                                localStorage.removeItem("term_id");
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 3 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 3 - homework</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${
                                          selectedSubjectId === elem.value
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) => {
                                            setSelectedSubjectId(elem?.value);
                                          }}
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          {homeWorkData.length > 0 ? (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Start Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="start_date"
                                  pattern="dd-mm-yyyy"
                                  max="9999-12-31"
                                  value={homeWorkData[0]?.start_date}
                                  onChange={(e) =>
                                    setHomeWorkDate(e.target.value)
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Start Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="start_date"
                                  pattern="dd-mm-yyyy"
                                  max="9999-12-31"
                                  {...register("start_date_lecture", {
                                    required: "Start date is required!",
                                    validate: (value) => {
                                      const selectedDate = new Date(value);
                                      const todayDate = new Date();
                                      return (
                                        selectedDate > todayDate ||
                                        "Please select a future date."
                                      );
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          )}
                          <EditHomework
                            subjectIds={selectedSubjectId}
                            setHomeWork={setHomeWork}
                            selectedSubjectId={selectedSubjectId}
                            singleTypeFormdata={singleTypeFormdata}
                            skillData={skillData}
                          />
                          <div className="modal-footer">
                            {learnigStep === 3 && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 3 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 3 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 3)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/admin/course-managment/course/all");
                                localStorage.removeItem("course_id");
                                localStorage.removeItem("term_id");
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 4 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 4 - E-Tutoring</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${
                                          selectedSubjectId === elem.value
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) => {
                                            setSelectedSubjectId(elem?.value);
                                          }}
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <EditeTutoring
                            setEtutoring={setEtutoring}
                            eTutroing={eTutroing}
                            setEtutoringData={setEtutoringData}
                            eTuoringData={eTuoringData}
                            selectedSubjectId={selectedSubjectId}
                            setSelectedSubjectId={setSelectedSubjectId}
                            singleTypeFormdata={singleTypeFormdata}
                          />
                          <div className="modal-footer">
                            {learnigStep === 4 && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 4 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 4 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/admin/course-managment/course/all");
                                localStorage.removeItem("course_id");
                                localStorage.removeItem("term_id");
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 5 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 5 - Consulting</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${
                                          selectedSubjectId === elem.value
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) => {
                                            setSelectedSubjectId(elem?.value);
                                          }}
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <EditConsulting
                            setConsulting={setConsulting}
                            consulting={consulting}
                            setConsultingData={setConsultingData}
                            ConsultingData={ConsultingData}
                            selectedSubjectId={selectedSubjectId}
                            setSelectedSubjectId={setSelectedSubjectId}
                            singleTypeFormdata={singleTypeFormdata}
                          />
                          <div className="modal-footer">
                            {learnigStep === 5 && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 5 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 5 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                navigate("/admin/course-managment/course/all");
                                localStorage.removeItem("course_id");
                                localStorage.removeItem("term_id");
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 6 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 6 - Evaluation</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          encType="multipart/form-data"
                        >
                          <EditEvaluation
                            setEvaluation={setEvaluation}
                            selectedSubjectId={selectedSubjectId}
                            setSelectedSubjectId={setSelectedSubjectId}
                            singleTypeFormdata={singleTypeFormdata}
                            testTypeData={testTypeData}
                            term_id={id}
                            course_id={course_id}
                            CallEvaluationList={CallEvaluationList}
                            year={year}
                            grade_id={grade_id}
                            setCheckEvaluationValidation={setCheckEvaluationValidation}
                          />
                          <div className="modal-footer">
                            {learnigStep === 6  && CheckEvaluationValidation &&  (
                              <button
                                type="button"
                                className="btn button"
                                onClick={() => onSubmitSteps()}
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 6 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 3)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 6 && (
                              <>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    navigate(
                                      "/admin/course-managment/course/all"
                                    );
                                    localStorage.removeItem("course_id");
                                    localStorage.removeItem("term_id");
                                  }}
                                >
                                  Submit
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                  {/* <div className="modal-footer">
                                        {currentStep > 1 && (
                                            <button className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                                Previous
                                            </button>
                                        )}
                                        {currentStep < 4 && (
                                            <button className="btn button">
                                                Next
                                            </button>
                                        )}
                                        {currentStep === 4 && (
                                            <button type="submit" className="btn button">
                                                Save
                                            </button>
                                        )}
                                    </div> */}
                </div>
              </div>
            </>
          )}
        </div>
        {/* <div className="modal-footer">
                        {currentStep > 1 && (
                            <button className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                Previous
                            </button>
                        )}
                        {currentStep < 2 && (
                            <button className="btn button">
                                Next
                            </button>
                        )}
                        {currentStep === 2 && (
                            <button type="submit" className="btn button">
                                Save
                            </button>
                        )}
                    </div>
                </form> */}
      </div>
    </div>
  );
};
export default EditCourse;
