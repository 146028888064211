import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button } from "semantic-ui-react";
import { couse_evaluation_list, active_couse_evaluation } from "../../../api/Class";
import { useForm } from "react-hook-form";
export const ActiveEvaluation = ({ isModalOpen, setIsModalOpen, id }) => {
  const [evaluationList, setEvaluationList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await couse_evaluation_list(id?.class_id, id?.branch_id);
      setEvaluationList(data?.data);
    };
    if (id?.class_id && id?.branch_id) {
      fetchData();
    }
  }, [id]);
  const onsubmit = async (evalId, newStatus) => {
    const payload = { status: newStatus };
    const result = await active_couse_evaluation(evalId, payload);

    if (result?.status) {
      setEvaluationList((prevList) =>
        prevList.map((ele) =>
          ele.id === evalId ? { ...ele, status: Number(newStatus) } : ele
        )
      );
    } else {
      alert("Failed to update status. Please try again.");
    }
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      className="modal-content"
      overlayClassName="modal-overlay"
      contentLabel=" Modal"
    >
      <div className="modal-header assign-student">
        <h1 className="modal-title fs-5">Edit Test Status</h1>
        <button
          type="button"
          onClick={() => {
            setIsModalOpen(false);
          }}
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <form className="modal-body">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" style={{ width: "15%" }}>Evaluation</th>
              <th scope="col" style={{ width: "15%" }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              evaluationList?.length > 0 ?
                evaluationList.map((ele) => (
                  <tr key={ele?.id}>
                    <td style={{ lineHeight: "47px" }}>{ele?.test_title}</td>
                    <td>
                      <select
                        className="form-select form-control"
                        value={ele?.status}
                        onChange={(e) => onsubmit(ele?.id, e.target.value)}
                      >
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </select>
                    </td>
                  </tr>
                ))
                : (
                  <tr style={{ borderBottom: "none" }}>
                    <td colSpan="2" className="text-center" style={{ width: "100%", borderBottom: "none" }}>
                      Evaluation data not available for this class
                    </td>
                  </tr>
                )
            }
          </tbody>
        </table>
        <div className="modal-footer">
          <Button
            type="button"
            className="btn button"
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};
