import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  // accordion-header active
  const [tabActive, setTabActive] = useState({
    settings: false
  });

  const location = useLocation();
  let role = localStorage.getItem('role');
      role = role === 'Branches' ? 'Branch' : role;

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("/branch/settings")) {
      setTabActive({ settings: true });
    } else {
      setTabActive({ settings: false });
    }
    console.log('currentPath', currentPath);
  }, [location]);

  return (
    <div id="layoutSidenav_nav">
      <div className="left-main-heading">
        <Link to="/branch/dashboard">
          <i className="fa-solid fa-table-cells-large"></i> Dashboard
        </Link>
      </div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2
            className={`accordion-header ${tabActive?.settings ? "active" : ""
              }`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              <i className="fa-solid fa-gears"></i> Settings
            </button>
          </h2>
          <div
            id="collapseTen"
            className={`accordion-collapse collapse ${tabActive?.settings ? "show" : ""
              }`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink
                  to="/branch/settings/users/all"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith(
                        "/branch/settings/user"
                      ) || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Users
                </NavLink>
                <NavLink
                  to="/branch/settings/edit"
                  className={({ isActive }) => {
                    const isActivePath =
                      location.pathname.startsWith(
                        "/branch/settings/edit"
                      ) || isActive;
                    return isActivePath ? "nav-link active" : "nav-link";
                  }}
                >
                  Branch Setting
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <div className="accordion-body">
            <nav className="sb-sidenav-menu-nested nav">
              <NavLink
                to="/branch/course/all"
                className={({ isActive }) => {
                  const isActivePath =
                    location.pathname.startsWith("/branch/course") || isActive;
                  return isActivePath ? "nav-link active" : "nav-link";
                }}
              >
                Courses
              </NavLink>
            </nav>
          </div>
        </div>
        <div className="accordion-item">
          <div className="accordion-body">
            <nav className="sb-sidenav-menu-nested nav">
              <NavLink
                to="/branch/question-list"
                className={({ isActive }) => {
                  const isActivePath =
                    location.pathname.startsWith("/branch/question-list") ||
                    isActive;
                  return isActivePath ? "nav-link active" : "nav-link";
                }}
              >
                Questionnaire List
              </NavLink>
            </nav>
          </div>
        </div>
        <div className="accordion-item">
          <div className="accordion-body">
            <nav className="sb-sidenav-menu-nested nav">
              <NavLink
                to="/branch/enroll/student_enroll-list"
                className={({ isActive }) => {
                  const isActivePath =
                    location.pathname.startsWith("/branch/enroll") || isActive;
                  return isActivePath ? "nav-link active" : "nav-link";
                }}
              >
                Students
              </NavLink>
            </nav>
          </div>
        </div>
        {/* <div className="accordion-item">
          <div className="accordion-body">
            <nav className="sb-sidenav-menu-nested nav">
              <NavLink
                to="/branch/free/free_student_list"
                className={({ isActive }) => {
                  const isActivePath =
                    location.pathname.startsWith("/branch/free") || isActive;
                  return isActivePath ? "nav-link active" : "nav-link";
                }}
              >
                Free Student
              </NavLink>
            </nav>
          </div>
        </div> */}
        <div className="accordion-item">
          <div className="accordion-body">
            <nav className="sb-sidenav-menu-nested nav">
              <NavLink
                to="/branch/teacher/all"
                className={({ isActive }) => {
                  const isActivePath =
                    location.pathname.startsWith("/branch/teacher") || isActive;
                  return isActivePath ? "nav-link active" : "nav-link";
                }}
              >
                Teachers
              </NavLink>
            </nav>
          </div>
        </div>
        <div className="accordion-item">
          <div className="accordion-body">
            <nav className="sb-sidenav-menu-nested nav">
              <NavLink
                to="/branch/class-management/all"
                className={({ isActive }) => {
                  const isActivePath =
                    location.pathname.startsWith("/branch/class-management") ||
                    isActive;
                  return isActivePath ? "nav-link active" : "nav-link";
                }}
              >
                Class Management
              </NavLink>
            </nav>
          </div>
        </div>
        <div className="accordion-item">
          <div className="accordion-body">
            <nav className="sb-sidenav-menu-nested nav">
              <NavLink
                to="/branch/student-attendance"
                className={({ isActive }) => {
                  const isActivePath =
                    location.pathname.startsWith(
                      "/branch/student-attendance"
                    ) || isActive;
                  return isActivePath ? "nav-link active" : "nav-link";
                }}
              >
                Attendance
              </NavLink>
            </nav>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Logged in as:</div>
          {role}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
