import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addWebsiteEnv,
  get_website_data,
  updateWebsiteEnv,
  dublicate_curr_term_data,
} from "../../../../apis/generalSetting/WebsiteEnv";
import { getCurrentWeekISO } from "../../../../Utils/Utils";
import { get_states } from "../../../../apis/Common";
import { handleNavigation } from "../../../../Utils/Utils";
const AddWebsiteEnv = () => {
  // const [id, setID] = useState("");
  const [statelist, setStateList] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [state, setState] = useState([]);
  const [Id, setId] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const id = useParams();
  const onSubmit = async (data) => {
    const add = [
      {
        name: data.term_name1,
        start_date: data.start_date1,
        max_week: data.max_week1,
      },
      {
        name: data.term_name2,
        start_date: data.start_date2,
        max_week: data.max_week2,
      },
      {
        name: data.term_name3,
        start_date: data.start_date3,
        max_week: data.max_week3,
      },
      {
        name: data.term_name4,
        start_date: data.start_date4,
        max_week: data.max_week4,
      },
    ];
    const update = termsData.map((apiTerm, index) => ({
      id: apiTerm.id,
      name: data[`term_name${index + 1}`],
      start_date: data[`start_date${index + 1}`],
      max_week: data[`max_week${index + 1}`],
      existing_state: state,
    }));
    // const termsDataWithIds = termsData.map((term, index) => ({
    //   id: term.id,
    //   name: data.name[index],
    //   start_date: data.start_date[index],
    //   max_week: data.max_week[index],
    // }));
    const payload = {
      term: id?.id?.length < 1 || id?.id == undefined ? add : update,
      Current_term: data.Current_term,
      Current_year: data.Current_year,
      Unit_test_start_week: data.Unit_test_start_week,
      Default_period4PA: data.Default_period4PA,
      SdateE: data.SdateE,
      SdateW: data.SdateW,
      SdateM: data.SdateM,
      SdateG: data.SdateG,
      Review_date: data.Review_date,
      Test_period: data.Test_period,
      Test_review_period: data.Test_review_period,
      Off_test_review_period: data.Off_test_review_period,
      Holi_off_test_review_Period: data.Holi_off_test_review_Period,
      Classtest_branches: data.Classtest_branches,
      group_email_password: data.group_email_password,
      default_path_for_wms: data.default_path_for_wms,
      smtp_email_account: data.smtp_email_account,
      smtp_password: data.smtp_password,
      cur_au: data.cur_au,
      cur_kr: data.cur_kr,
      cur_mark_au: data.cur_mark_au,
      cur_mark_kr: data.cur_mark_kr,
      state: data.state,
    };
    // console.log('env payload',payload);

    // return
    if (id?.id?.length < 1 || id?.id == undefined) {
      // return
      let result = await addWebsiteEnv(payload);
      if (result.status) {
        return navigate("/admin/administrator-setting/websiteEnv/all");
      }
    } else {
      // return
      let result = await updateWebsiteEnv(id?.id, payload);
      if (result.status) {
        return navigate("/admin/administrator-setting/websiteEnv/all");
      }
    }
  };
  const today = new Date().toISOString().split("T")[0];
  const terms = [0, 1, 2, 3];
  console.log(id);
  useEffect(() => {
    async function get_data() {
      const data = await get_website_data(id);
      setState(data?.data?.state);
      setTermsData(data.data.term);
      data?.data?.term.forEach((term, index) => {
        setValue(`term_name${index + 1}`, term.name);
        setValue(`start_date${index + 1}`, term.start_date);
        setValue(`max_week${index + 1}`, term.max_week || "");
      });
      setId(data?.data?.id);
      setValue("Current_term", data?.data?.Current_term);
      setValue("Current_year", data?.data?.Current_year);
      setValue("Unit_test_start_week", data?.data?.Unit_test_start_week);
      setValue("Default_period4PA", data?.data?.Default_period4PA);
      setValue("SdateE", data?.data?.SdateE);
      setValue("SdateW", data?.data?.SdateW);
      setValue("SdateM", data?.data?.SdateM);
      setValue("SdateG", data?.data?.SdateG);
      setValue("Review_date", data?.data?.Review_date);
      setValue("Test_period", data?.data?.Test_period);
      setValue("Test_review_period", data?.data?.Test_review_period);
      setValue("Off_test_review_period", data?.data?.Off_test_review_period);
      setValue(
        "Holi_off_test_review_Period",
        data?.data?.Holi_off_test_review_Period
      );
      setValue("Classtest_branches", data?.data?.Classtest_branches);
      setValue("group_email_password", data?.data?.group_email_password);
      setValue("default_path_for_wms", data?.data?.default_path_for_wms);
      setValue("smtp_email_account", data?.data?.smtp_email_account);
      setValue("smtp_password", data?.data?.smtp_password);
      setValue("cur_au", data?.data?.cur_au);
      setValue("cur_kr", data?.data?.cur_kr);
      setValue("cur_mark_au", data?.data?.cur_mark_au);
      setValue("cur_mark_kr", data?.data?.cur_mark_kr);
      setTimeout(() => {
        setValue("state", data?.data?.state);
      }, 1000);
      // setID(envView?.data[0]?.id);
    }
    if (id?.id?.length > 0) {
      get_data();
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const stateList = await get_states();
      setStateList(stateList?.data);
    };
    fetchData();
  }, []);
  //dublicate term data
  const DublicateTermData = async (id) => {
    let result = await dublicate_curr_term_data(id);
    if (result.status) {
      return navigate("/admin/administrator-setting/websiteEnv/all");
    }
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Website ENV</h4>
          <Link
            className="edit-btn"
            to={`/admin/administrator-setting/websiteEnv/all`}
            onClick={(e) => {
              handleNavigation(e, isDirty);
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <section class="form-section">
              <div class="container fromsec-inner">
                <div class="row">
                  <div class="col-12 col-md-4 border-right_c">
                    <div class="form-left">
                      <div class="from-heading_s">State *</div>
                      <div class="form-left-cnts">
                        <div class="form-cnts-inputs">
                          <div class="row">
                            <div class="col-12">
                              <label class="label-txt">
                                Group Email Password
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Group Email Password"
                                {...register("group_email_password")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">
                                Default Path for WMS
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Default path for wms"
                                {...register("default_path_for_wms")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">
                                SMTP Email Account
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Smtp Email Account"
                                {...register("smtp_email_account")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">SMTP Password</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Smtp Password"
                                {...register("smtp_password")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">CUR_AU</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Cur Au"
                                {...register("cur_au")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">CUR_KR</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Cur Kr"
                                {...register("cur_kr")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">Car Mark Au</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Cur Mark Au"
                                {...register("cur_mark_au")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">Car Mark Kr</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Cur Mark Kr"
                                {...register("cur_mark_kr")}
                              />
                            </div>
                            <div class="col-12">
                              <label class="label-txt">
                                Off Test review Period *
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Off Test Review Period"
                                {...register("Off_test_review_period", {
                                  required: true,
                                })}
                              />
                              {errors.Off_test_review_period &&
                                errors.Off_test_review_period.type ===
                                  "required" && (
                                  <span className="validation-error">
                                    This is required
                                  </span>
                                )}
                            </div>
                            <div class="col-12">
                              <label class="label-txt">
                                Holiday Off Test review Period *
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Holi Off Test Review Period"
                                {...register("Holi_off_test_review_Period", {
                                  required: true,
                                })}
                              />
                              {errors.Holi_off_test_review_Period &&
                                errors.Holi_off_test_review_Period.type ===
                                  "required" && (
                                  <span className="validation-error">
                                    This is required
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-8">
                    <div class="form-right">
                      <div class="select-options">
                        {/* <labell>State </labell> */}
                        <select
                          className="form-select form-control"
                          {...register("state", { required: true })}
                        >
                          {statelist?.length > 0
                            ? [
                                <option value="">Choose State</option>,
                                statelist?.map((elem) => {
                                  return (
                                    <option value={elem?.id}>
                                      {elem?.name}
                                    </option>
                                  );
                                }),
                              ]
                            : [<option value="">State not available</option>]}
                        </select>
                        {errors.state && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                      </div>
                      <div class="form-right-cnts">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="form-cnts-1">
                              <div class="form-cnts-inputs">
                                <div class="row">
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Term 1 :Name *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Term Name"
                                      {...register("term_name1", {
                                        required: true,
                                      })}
                                    />
                                    {errors.term_name1 &&
                                      errors.term_name1.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Start Date Term 1 *
                                    </label>
                                    <input
                                      type="date"
                                      max="9999-12-31"
                                      class="form-control"
                                      id="inputdate"
                                      min={today}
                                      {...register("start_date1", {
                                        required: true,
                                      })}
                                    />
                                    {errors.start_date1 &&
                                      errors.start_date1.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Term 2 :Name *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Term Name"
                                      {...register("term_name2", {
                                        required: true,
                                      })}
                                    />
                                    {errors.term_name2 &&
                                      errors.term_name2.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Start Date Term 2 *
                                    </label>
                                    <input
                                      type="date"
                                      max="9999-12-31"
                                      class="form-control"
                                      id="inputdate1"
                                      min={today}
                                      {...register("start_date2", {
                                        required: true,
                                      })}
                                    />
                                    {errors.start_date2 &&
                                      errors.start_date2.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Term 3 :Name *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Term Name"
                                      {...register("term_name3", {
                                        required: true,
                                      })}
                                    />
                                    {errors.term_name3 &&
                                      errors.term_name3.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Start Date Term 3 *
                                    </label>
                                    <input
                                      type="date"
                                      max="9999-12-31"
                                      class="form-control"
                                      id="inputdate2"
                                      min={today}
                                      {...register("start_date3", {
                                        required: true,
                                      })}
                                    />
                                    {errors.start_date3 &&
                                      errors.start_date3.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Term 4 :Name *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Term Name"
                                      {...register("term_name4", {
                                        required: true,
                                      })}
                                    />
                                    {errors.term_name4 &&
                                      errors.term_name4.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Start Date Term 4 *
                                    </label>
                                    <input
                                      type="date"
                                      max="9999-12-31"
                                      class="form-control"
                                      id="inputdate3"
                                      min={today}
                                      {...register("start_date4", {
                                        required: true,
                                      })}
                                    />
                                    {errors.start_date4 &&
                                      errors.start_date4.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Max Week Term 1 *
                                    </label>
                                    <input
                                      type="text"
                                      id="weekPicker"
                                      class="form-control"
                                      min={getCurrentWeekISO()}
                                      placeholder="Enter Max Week Term 1"
                                      {...register("max_week1", {
                                        required: true,
                                      })}
                                    />
                                    {errors.max_week1 &&
                                      errors.max_week1.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Max Week Term 2 *
                                    </label>
                                    <input
                                      type="text"
                                      id="max_week2"
                                      class="form-control"
                                      min={getCurrentWeekISO()}
                                      placeholder="Enter Max Week Term 2"
                                      {...register("max_week2", {
                                        required: true,
                                      })}
                                    />
                                    {errors.max_week2 &&
                                      errors.max_week2.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Max Week Term 3 *
                                    </label>
                                    <input
                                      type="text"
                                      id="max_week3"
                                      class="form-control"
                                      placeholder="Enter Max Week Term 3"
                                      min={getCurrentWeekISO()}
                                      {...register("max_week3", {
                                        required: true,
                                      })}
                                    />
                                    {errors.max_week3 &&
                                      errors.max_week3.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Max Week Term 4 *
                                    </label>
                                    <input
                                      type="text"
                                      id="weekPicker4"
                                      class="form-control"
                                      placeholder="Enter Max Week Term 4"
                                      min={getCurrentWeekISO()}
                                      {...register("max_week4", {
                                        required: true,
                                      })}
                                    />
                                    {errors.max_week4 &&
                                      errors.max_week4.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <div class="form-cnts-1 form-cnts-2">
                              <div class="form-cnts-inputs">
                                <div class="row">
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Current year *
                                    </label>
                                    <input
                                      type="text"
                                      id="current-year"
                                      className="form-control"
                                      placeholder="Enter Current Year"
                                      {...register("Current_year", {
                                        required: "Year is required",
                                        pattern: {
                                          value: /^\d{4}$/,
                                          message:
                                            "Enter a valid year (e.g., 2025)",
                                        },
                                        validate: {
                                          isCurrentOrFutureYear: (value) => {
                                            const currentYear =
                                              new Date().getFullYear();
                                            return (
                                              value >= currentYear ||
                                              `Year must be ${currentYear} or later`
                                            );
                                          },
                                        },
                                      })}
                                    />

                                    {errors.Current_year && (
                                      <p className="text-danger">
                                        {errors.Current_year.message}
                                      </p>
                                    )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Current Term *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Current Term"
                                      {...register("Current_term", {
                                        required: true,
                                      })}
                                    />
                                    {errors.Current_term &&
                                      errors.Current_term.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Unit Test Start Week *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Test Start Week "
                                      {...register("Unit_test_start_week", {
                                        required: true,
                                      })}
                                    />
                                    {errors.Unit_test_start_week &&
                                      errors.Unit_test_start_week.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Default period 4 PA
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Default Period 4 PA"
                                      {...register("Default_period4PA")}
                                    />
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">Sdate_e *</label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="Enter Sdate E"
                                      pattern="dd-mm-yyyy"
                                      min={today}
                                      {...register("SdateE", {
                                        required: "Sdate is required!",
                                        validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return (
                                            selectedDate > todayDate ||
                                            "Please select a future date."
                                          );
                                        },
                                      })}
                                    />
                                    {errors.SdateE &&
                                      errors.SdateE.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">SDate_w *</label>
                                    <input
                                      type="date"
                                      max="9999-12-31"
                                      className="form-control"
                                      placeholder="Enter Sdate W"
                                      pattern="dd-mm-yyyy"
                                      min={today}
                                      {...register("SdateW", {
                                        required: "Sdate is required!",
                                        validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return (
                                            selectedDate > todayDate ||
                                            "Please select a future date."
                                          );
                                        },
                                      })}
                                    />
                                    {errors.SdateW &&
                                      errors.SdateW.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">SDate_m *</label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="Enter Sdate M"
                                      pattern="dd-mm-yyyy"
                                      max="9999-12-31"
                                      min={today}
                                      {...register("SdateM", {
                                        required: "Sdate is required!",
                                        validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return (
                                            selectedDate > todayDate ||
                                            "Please select a future date."
                                          );
                                        },
                                      })}
                                    />
                                    {errors.SdateM &&
                                      errors.SdateM.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">SDate_g *</label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="Enter Sdate G"
                                      pattern="dd-mm-yyyy"
                                      max="9999-12-31"
                                      min={today}
                                      {...register("SdateG", {
                                        required: "Sdate is required!",
                                        validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return (
                                            selectedDate > todayDate ||
                                            "Please select a future date."
                                          );
                                        },
                                      })}
                                    />
                                    {errors.SdateG &&
                                      errors.SdateG.type === "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Review Date *
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="Enter Review Date"
                                      pattern="dd-mm-yyyy"
                                      max="9999-12-31"
                                      min={today}
                                      {...register("Review_date", {
                                        required: "Sdate is required!",
                                        validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return (
                                            selectedDate > todayDate ||
                                            "Please select a future date."
                                          );
                                        },
                                      })}
                                    />
                                    {errors.Review_date &&
                                      errors.Review_date.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Class Test Branches
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Class Test Branches"
                                      {...register("Classtest_branches")}
                                    />
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Test Period *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Test Period"
                                      {...register("Test_period", {
                                        required: true,
                                      })}
                                    />
                                    {errors.Test_period &&
                                      errors.Test_period.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                  <div class="col-12">
                                    <label class="label-txt">
                                      Test review Period *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Test Review Period"
                                      {...register("Test_review_period", {
                                        required: true,
                                      })}
                                    />
                                    {errors.Test_review_period &&
                                      errors.Test_review_period.type ===
                                        "required" && (
                                        <span className="validation-error">
                                          This is required
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-deobuttons">
                <div class="container frm-deo-inner">
                  {id?.id?.length < 1 || id?.id == undefined ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      onClick={() => DublicateTermData(Id)}
                      class="primary-btn"
                    >
                      Duplicate For Next Year
                    </button>
                  )}
                  <button type="submit" class="primary-btn">
                    Submit
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddWebsiteEnv;
